import {
    /**Register external esignature user */
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    REGISTER_CLEAR_DATA,
    /**Login external users */
    LOGIN, 
    LOGIN_CLEAR_DATA, 
    LOGIN_ERROR, 
    LOGIN_SUCCESS,
} from "./actionType"


/**REGISTER ESIGNATURE USER */
export const register = (user, navigate) => {
    return {
        type: REGISTER,
        payload: { user, navigate },
    }
}

export const registerSuccess = user => {
    return {
        type: REGISTER_SUCCESS,
        payload: user,
    }
}

export const registerError = error => {
    return {
        type: REGISTER_ERROR,
        payload: error,
    }
}

export const registerClearData = () => {
    return {
        type: REGISTER_CLEAR_DATA,
        payload: {}
    };
};
/**END Esignature register */
/**LOGIN Esignature external users */
export const login = (user, navigate) => {
    return {
        type: LOGIN,
        payload: {user, navigate},
    }
}

export const loginSuccess = user => {
    return {
        type: LOGIN_SUCCESS,
        payload: user,
    }
}

export const loginError = error => {
    return {
        type: LOGIN_ERROR,
        payload: error,
    }
}

export const loginClearData = () => {
    return {
        type: LOGIN_CLEAR_DATA,
        payload: {}
    };
};