import React from "react";
import MetaTags from "react-meta-tags";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DownloadIcon from "@mui/icons-material/Download";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";

import {LiBack, LiDownload, Nav, Ul, WrapperLabeling} from "./style";
import DocumentListDocPlace from "./documentList";

const DocViewerVieweSignature = (props) => {
    const navigate = useNavigate();
    return(
        <React.Fragment>
            <div className="page-contect">
                <MetaTags>
                    <title>eSignature Doc Viewer</title>
                </MetaTags>
                <Nav>
                    <Ul>
                        <LiBack className="backBtn">
                            <Button
                                color="primary"
                                onClick={() => { navigate('/home') }}
                                startIcon={<ArrowBackIosNewIcon />}
                                sx={{textTransform: 'none'}}
                            >
                                Back
                            </Button>
                        </LiBack>
                        <LiDownload>
                            <Button
                                color="primary"
                                onClick={props.downloadFile}
                                startIcon={<DownloadIcon />}
                                sx={{textTransform: 'none'}}
                            >
                                Download
                            </Button>
                        </LiDownload>
                    </Ul>
                </Nav>
                <WrapperLabeling>
                    <Grid container >
                        <Grid className="wrapperAside" item xs={2} md={2}>
                            <DocumentListDocPlace
                                documents={props.documents}
                                getDocument={props.getDocument}
                            />
                        </Grid>
                        <Grid className="wrapperAside" item xs={10} md={10}>
                            <div className="documentShowcase">
                                <img
                                    style={{ width: "100%", pointerEvents: "none" }}
                                    src={props.currentDocument}
                                    alt="Current document"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </WrapperLabeling>
            </div>
        </React.Fragment>
    )
}

export default DocViewerVieweSignature