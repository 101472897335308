import React from "react";
import { TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DatePickerDocPlace = ({ field, setField, label, inputFormat }) => {
  /**onchange management */
  const setDate = (e) => {
    setField(e.format(inputFormat ? inputFormat : "MM/DD/YYYY"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        label={label}
        inputFormat={inputFormat ? inputFormat : "MM/DD/YYYY"}
        value={field}
        onChange={setDate}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </LocalizationProvider>
  );
};

export default DatePickerDocPlace;
