import {
    /**Get all documents of signature */
    DOCUMENT_LIST,
    DOCUMENT_LIST_SUCCESS,
    DOCUMENT_LIST_ERROR,
    DOCUMENT_LIST_CLEAR_DATA,

    /**stamp esignature */
    API_ERROR_DOCUMENT_PAGES,
    DOCUMENT_PAGES_LIST,
    DOCUMENT_PAGES_LIST_SUCCESS,
    LIST_REQUIRE_SIGNATURE_DOCUMENT,
    LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA,
    LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR,
    LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS,

} from "./actionType"

const initialState = {
    /**get the document list of signature */
    errorDocumentList: null,
    responseDoumentList: null,
    loadingDocumentList: false,
    /**END */

    /**BEGIN STAMP esignature */
    errorDocumentPages: null,
    responseDocumentPages: null,
    loadingDocumentPages: false,

    responseListRequireSignatureDocument: null,
    loadingListRequireSignatureDocument: false,
    errorListRequireSignatureDocument: null,
    responseResendSignatureRequest: null,
    /**END stamp esignature */
}

const document = (state = initialState, action) => {
    switch (action.type) {

        /**Create user login  */
        case DOCUMENT_LIST:
            state = {
                ...state,
                errorDocumentList: null,
                loadingDocumentList: true,
            }
            break;
        case DOCUMENT_LIST_SUCCESS:
            state = {
                ...state,
                errorDocumentList: null,
                responseDoumentList: action.payload,
                loadingDocumentList: false,
            }
            break;
        case DOCUMENT_LIST_ERROR:
            state = {
                ...state,
                errorDocumentList: action.payload,
                responseDoumentList: null,
                loadingDocumentList: false,
            }
            break;
        case DOCUMENT_LIST_CLEAR_DATA:
            state = {
                ...state,
                errorDocumentList: null,
                responseDoumentList: null,
                loadingDocumentList: false,
            }
            break;
        /**end */
        /**BEGIN esignature stamp */
        case DOCUMENT_PAGES_LIST_SUCCESS:
            state = {
                ...state,
                errorDocumentPages: null,
                responseDocumentPages: action.payload,
                loadingDocumentPages: false,
            }
            break;
        case API_ERROR_DOCUMENT_PAGES:
            state = {
                ...state,
                errorDocumentPages: action.payload,
                loadingDocumentPages: false,
                responseDocumentPages: null
            }
            break;
        case DOCUMENT_PAGES_LIST:
            state = {
                ...state,
                errorDocumentPages: null,
                loadingDocumentPages: true,
            }
            break;

        /**esignature required */
        case LIST_REQUIRE_SIGNATURE_DOCUMENT:
            state = {
                ...state,
                errorListRequireSignatureDocument: null,
                loadingListRequireSignatureDocument: true,
            }
            break;
        case LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS:
            state = {
                ...state,
                errorListRequireSignatureDocument: null,
                loadingListRequireSignatureDocument: false,
                responseListRequireSignatureDocument: action.payload,
            }
            break;
        case LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR:
            state = {
                ...state,
                errorListRequireSignatureDocument: action.payload,
                loadingListRequireSignatureDocument: false,
                responseListRequireSignatureDocument: null,
            }
            break;
        case LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA:
            state = {
                ...state,
                errorListRequireSignatureDocument: null,
                responseListRequireSignatureDocument: null,
                loadingListRequireSignatureDocument: false,
            }
            break;
        /**END esignature stamp */
        default:
            state = { ...state }
            break;
    }
    return state
}

export default document;