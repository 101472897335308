import React from "react";
import {TextField} from "@mui/material";
import {useStyles} from './style'

const InputFormDocPlace = ({ id, field, setField, type, label, helperText, expresionRegular, required, errorMessage, maxLength, disabled }) => {
    const classes = useStyles();

    const onChange = (e) => {
        setField({ ...field, value: e.target.value, error: false, errorMessage: '' });
    }

    const validation = (e) => {
        if (expresionRegular) {
            if (!expresionRegular.test(field.value))
                setField({ ...field, error: true, errorMessage: errorMessage });
        } else if (required) {
            if (!field.value)
                setField({ ...field, error: true, errorMessage: errorMessage });
        }
    }

    return (
        <TextField
            error={field.error}
            required={required}
            className={classes.inputForm}
            value={field.value}
            onChange={onChange}
            onKeyUp={validation.bind(this)}
            onBlur={validation}
            helperText={helperText}
            id={id}
            type={type}
            multiline={false}
            label={label}
            variant="outlined"
            disabled={disabled}
            fullWidth
            inputProps={{
                autoComplete: 'new-password',
                form: {
                    autoComplete: 'off',
                },
                maxLength: maxLength
            }}
        />
    )
}


export default InputFormDocPlace

