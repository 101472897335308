import axios from 'axios'

const API_URL = process.env.REACT_APP_HOST_API;

const setToken = () => {
    if (localStorage.getItem('esignature')) {
        return `Bearer ${JSON.parse(localStorage.getItem('esignature')).token}`;
    }
}

const setUserId = () => {
    if (localStorage.getItem("esignature")) {
        return JSON.parse(localStorage.getItem("esignature")).userId;
    }
};

const setCustomerId = () => {
    if (localStorage.getItem("esignature")) {
        return (JSON.parse(localStorage.getItem("esignature")).customerId) ?
            JSON.parse(localStorage.getItem("esignature")).customerId : "";
    }
};

const setDoctypeId = () => {
    if (localStorage.getItem("esignature")) {
        return JSON.parse(localStorage.getItem("esignature")).doctypeId;
    }
};

const axiosApi = axios.create({
    baseURL: API_URL,
});

axiosApi.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);

const setHeaders = () => {
    axiosApi.defaults.headers.common["Authorization"] = setToken();
    axiosApi.defaults.headers.common["customerId"] = setCustomerId();
    axiosApi.defaults.headers.common["doctypeId"] = setDoctypeId();
    axiosApi.defaults.headers.common["userId"] = setUserId();
};

export async function get(url, config = {}) {
    setHeaders();
    return await axiosApi
        .get(url, { ...config })
        .then((response) => response.data);
}

export async function post(url, data, config = {}) {
    setHeaders();
    return axiosApi
        .post(url, { ...data }, { ...config })
        .then((response) => response.data);
}

export async function put(url, data, config = {}) {
    setHeaders();
    return axiosApi
        .put(url, { ...data }, { ...config })
        .then((response) => response.data);
}

export async function del(url, data, config = {}) {
    setHeaders();
    return axiosApi
        .delete(url, { ...config, data })
        .then((response) => response.data);
}

export async function getDoc(url, config = {}) {
    setHeaders();
    return await axiosApi
    .get(url, { responseType: "blob", ...config })
    .then((response) => response.data);
}