import http from "./http-common";

export const requiredESignature = (body) => {

    let url = process.env.REACT_APP_HOST_API + `/automation/api/v1.0/RequiredEsignature`;
    return http.put(url, body, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
            "customerId": (JSON.parse(localStorage.getItem("esignature")).customerId) ? JSON.parse(localStorage.getItem("esignature")).customerId : "",

        }
    });

};

/* Process Sign*/

export const SignDocumentByRequest = (id) => {

    let url = process.env.REACT_APP_HOST_API + `/automation/api/v1.0/signature/user/SignDocumentByRequest/${id}`;
    return http.post(url, {}, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
            "customerId": JSON.parse(localStorage.getItem("esignature")).customerId,
        
        }
    });

};

/* Save Labeled data */
export const saveLabeledData = (document, body) => {

    let url = process.env.REACT_APP_HOST_API + `/automation/api/v1.0/EsignatureProcess/CreateLabelData`;
    return http.post(url, body, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
            "customerId": (JSON.parse(localStorage.getItem("esignature")).customerId) ? JSON.parse(localStorage.getItem("esignature")).customerId : "",
            "documentId": document
        }
    });

};

/* Get signature process */

export const getIdRequestSignatureByDocumentId = (documentId) => {  /**new */

    let url = process.env.REACT_APP_HOST_API + `/automation/api/v1.0/RequiredEsignature/GetIdRequestSignatureByDocumentId`;
    return http.get(url, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
            "customerId": JSON.parse(localStorage.getItem("esignature")).customerId,
            "documentId": documentId
        }
    });

};


/* Get Labeled data */

export const getEsignatureData = (documentId) => {

    let url = process.env.REACT_APP_HOST_API + `/automation/api/v1.0/EsignatureProcess/GetLabelData`;
    return http.get(url, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
            "customerId": JSON.parse(localStorage.getItem("esignature")).customerId,
            "documentId": documentId
        }
    });

};


/* Delete Labeled data */

export const deleteLabeledData = (documentId) => {

    let url = process.env.REACT_APP_HOST_API + `/automation/api/v1.0/EsignatureProcess/DeleteLabelData`;
    return http.delete(url, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
            "customerId": JSON.parse(localStorage.getItem("esignature")).customerId,
            "documentId": documentId
        }
    });

};

/* Update Labeled data */

export const updateLabeledData = (documentId, body) => {

    let url = process.env.REACT_APP_HOST_API + `/automation/api/v1.0/EsignatureProcess/UpdateLabelData`;
    return http.put(url, body, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
            "customerId": JSON.parse(localStorage.getItem("esignature")).customerId,
            "documentId": documentId
        }
    });

};

/* Get Labeled data */

export const getEsignature = (id) => {

    let url = process.env.REACT_APP_HOST_API + `/automation/api/v1.0/Signature/User/${id}/All`;
    return http.get(url, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
            "customerId": JSON.parse(localStorage.getItem("esignature")).customerId,
            "userId": JSON.parse(localStorage.getItem("esignature")).userId

        }
    });

};


