import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { TextField } from "@mui/material";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";

import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  ContainerTextField,
  DivHelperTextTitle,
  useStyles,
  WrapperCheckBox,
} from "./style";
import { colors } from "../../../theme";

const CheckBoxView = (props) => {
  const classes = useStyles();
  return (
    <WrapperCheckBox>
      <Container>
        {props.type === "view" ? (
          <>
            <DivHelperTextTitle>{props.titleCheckBox}</DivHelperTextTitle>
            {props.checkItem?.map((v) => (
              <FormControlLabel
                key={v.order}
                control={
                  <Checkbox
                    value={v.value}
                    defaultChecked={props.defaultChecked}
                    onChange={props.onChangeCheckBox}
                    required={props.required}
                  />
                }
                label={v.label}
              />
            ))}

            <section className={classes.subtitle}>
              {props.isChecked && (
                <div style={{ color: "red" }}>*Mandatory</div>
              )}
              {props.subTitleCheckBox}
            </section>
          </>
        ) : (
          <>
            <ButtonContainer>
              <Button
                variant="text"
                onClick={props.onChangeNotSeeIntoPDF}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notIntoPdfCheckB && colors.gray3,
                }}
                startIcon={<VisibilityOffIcon />}
              >
                Not see into PDF
              </Button>
            </ButtonContainer>
            <ContainerTextField>
              <TextField
                id="helpTextTitle"
                placeholder="Click to Enter Title"
                fullWidth={true}
                variant="standard"
                onChange={props.onChangeTitleCheckBox}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                  },
                }}
              />
            </ContainerTextField>
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  defaultChecked={props.defaultChecked}
                  onChange={props.onChangeCheckBox}
                />
              }
              label={props.checkBoxLabel}
            />
            <ContainerTextField>
              <TextField
                id="helpTestDescription"
                placeholder="Click to Enter Description"
                fullWidth={true}
                variant="standard"
                onChange={props.onChangeSubTitleCheckBox}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "lighter",
                  },
                }}
              />
            </ContainerTextField>
            <ButtonContainerTools>
              <Button
                onClick={props.onChangeSettingCheckBox}
                style={{
                  textTransform: "none",
                  marginRight: "20px",
                }}
                variant={"text"}
                startIcon={<SettingsSuggestIcon />}
              >
                Settings
              </Button>
              <Button
                onClick={props.onChangeDeleteCheckBox}
                style={{
                  textTransform: "none",
                }}
                color="error"
                variant={"text"}
                startIcon={<DeleteForeverIcon />}
              >
                Delete
              </Button>
            </ButtonContainerTools>
          </>
        )}
      </Container>
    </WrapperCheckBox>
  );
};

export default CheckBoxView;
