import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import TitleDocPlace from "../../Components/MasterPageInternal/Components/Title/title";
import GridAssignedForms from "./resouerce/grid/grid";
import Loading from "../../Components/Resources/loading";
import SearchDocPlace from "../../Components/search/search";
import { WrapperAssignedForms } from "./style";
import AssignedFormsViewDocPlace from "./view";
import { getFormsRequested } from "../../Store/Forms/actions";

const AssignedForms = () => {
  const dispatch = useDispatch();
  const [allFormsRequested, setAllFormsRequested] = useState([]);
  const [email] = useState(JSON.parse(localStorage.getItem("esignature")));
  const [documentName, setDocumentName] = useState([]);

  const { responseGetAllFormsRequested, loadingGetAllFormsRequested } =
    useSelector((state) => ({
      responseGetAllFormsRequested:
        state.AllFormsRequested.responseGetAllFormsRequested,
      loadingGetAllFormsRequested:
        state.AllFormsRequested.loadingGetAllFormsRequested,
    }));

  const findDocumentTypes = (term) => {
    setAllFormsRequested(
      term
        ? documentName.filter((doc) =>
            doc.DocumentName.toLowerCase().includes(term)
          )
        : documentName
    );
  };

  const searchFile = (filterSearch) => {
    findDocumentTypes(filterSearch);
  };

  useEffect(() => {
    if (!responseGetAllFormsRequested) {
      dispatch(getFormsRequested({ email: email.email }));
    }
  }, [dispatch, email.email, responseGetAllFormsRequested]);

  useEffect(() => {
    let newObject = [];
    if (Array.isArray(responseGetAllFormsRequested)) {
      responseGetAllFormsRequested?.forEach((formsRequested, i) => {
        newObject.push({ ...formsRequested, id: i });
      });
      setAllFormsRequested(newObject);
      setDocumentName(newObject);
    }
  }, [responseGetAllFormsRequested]);

  return (
    <React.Fragment>
      <AssignedFormsViewDocPlace />
      <Container fluid>
        <TitleDocPlace title={"Assigned forms"} viewActive={false} />
        <Loading
          show={loadingGetAllFormsRequested}
          text={"Obtaining forms..."}
        />
        <SearchDocPlace callSearch={searchFile.bind(this)} />
        <WrapperAssignedForms>
          <GridAssignedForms rows={allFormsRequested} />
        </WrapperAssignedForms>
      </Container>
    </React.Fragment>
  );
};

export default AssignedForms;
