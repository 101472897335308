import React from 'react'
import { AppBar, Toolbar, Box, styled, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import character from '../../Assets/character.png';
import logo from '../../Assets/logo2.svg';
import plant from '../../Assets/plant.png'
import WindowWrapper from '../Form/windowWrapper';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const MasterPage = (props) => {
    return (
        <WindowWrapper>
            <AppBar position="fixed" >
                <Toolbar>
                    <img src={logo} alt="Logo" style={{ width: "200px" }} />
                    <div>{`${process.env.REACT_APP_NAME}-v${process.env.REACT_APP_VERSION}`}</div>
                </Toolbar>
            </AppBar>
                <Grid container spacing={3} sx={{height:"100%"}}>
                    <Grid item xs={0} sm={3} md={3} lg={4} >
                        <Box sx={{ width: "100%", height: "100%", position: "relative", display: { xs: 'none', md: 'block' } }} >
                            <img src={plant} alt="Plant" style={{ height: "200px", bottom: "0px", right: "0px", position: "absolute" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <DrawerHeader />
                        <Box sx={{ marginLeft:"10px", marginRight:"10px" }}  >
                            <Outlet />
                        </Box>
                    </Grid>
                    <Grid item xs={0} sm={3} md={3} lg={4}>
                        <Box sx={{ width: "100%", height: "100%", position: "relative", display: { xs: 'none', md: 'block' } }} >
                            <img src={character} alt="Character" style={{ height: "400px", bottom: "0px", left: "0px", position: "absolute" }} />
                        </Box>
                    </Grid>
                </Grid>
        </WindowWrapper>
    );
}
export default MasterPage;
