import styled from 'styled-components';
import {colors} from '../../Components/Resources/index';

export const WrapperLabeling = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${colors.ground};
    margin-top: 20px;
    overflow: hidden;
`;

export const Nav = styled.nav`
    border-radius: 5px;
    background-color: #4546460a;
    width: 100%;
`;

export const Ul = styled.ul`
  position: relative;
  list-style-type: none;
  display: inline-block;
  width: 100%;
  height: 20px;
`;
export const LiBack = styled.li`
  display: inline-block;
  float: left;
  margin: -4px 0 0 -30px;
`;
export const LiShare = styled.li`
  display: inline-block;
  margin-left: 15px;
  float: left;
  margin-top: -4px ;
`;

export const LiDownload = styled.li`
  display: inline;
  margin-right: 50px;
  float: right;
  margin-top: -4px ;
  color: red;
`;
export const Wrapper = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 4px;

    margin-top: 0px;
    margin-bottom: 10px;
`;