import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import MasterPage from "./Components/MastePage";
import RegisterSteps from "./DocPlace/RegisterSteps";
import Login from "./DocPlace/Login/login";
import MasterPageInternal from "./Components/MasterPageInternal";
import DocumentSummary from "./DocPlace/DocumentSummary";
import StampSignature from "../src/DocPlace/StampSignature/labeling";
import { DocViewer } from "./DocPlace/DocViewer/labeling";
import AssignedForms from "./DocPlace/AssignedForms";
import { FormViewer } from "./DocPlace/AssignedForms/formsViewer/labeling";
import { SeeCompletedForm } from "./DocPlace/AssignedForms/seeCompletedForm/labeling";

export default function App() {
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route element={<MasterPage />}>
            <Route exact path="/register/:email?" element={<RegisterSteps />} />
          </Route>
          <Route element={<MasterPageInternal />}>
            <Route exact path="/home" element={<DocumentSummary />} />
            <Route
              exact
              path="/stampSignature/:id"
              element={<StampSignature />}
            />
            <Route exact path="/docViewer/:id" element={<DocViewer />} />
            <Route exact path="/forms-Requests" element={<AssignedForms />} />
            <Route
              exact
              path="/forms-viewer/:id/:idRequest"
              element={<FormViewer />}
            />
            <Route
              exact
              path="/completedForm/:id"
              element={<SeeCompletedForm />}
            />
          </Route>
          <Route exact path="/" element={<Login />} />
        </Routes>
      </Fragment>
    </Router>
  );
}
