import {
  REQUIRED_SIGNATURE_DOCUMENTS,
  REQUIRED_SIGNATURE_DOCUMENTS_CLEAR_DATA,
  REQUIRED_SIGNATURE_DOCUMENTS_ERROR,
  REQUIRED_SIGNATURE_DOCUMENTS_SUCCESS,
  VALIDATE_USER_SIGNATURE,
  VALIDATE_USER_SIGNATURE_CLEAR_DATA,
  VALIDATE_USER_SIGNATURE_ERROR,
  VALIDATE_USER_SIGNATURE_SUCCESS,
} from "./actionTypes";

// Validates if the user is registered in eSignature
export const getValidateUserSignature = () => {
  return {
    type: VALIDATE_USER_SIGNATURE,
    payload: {},
  };
};

export const getValidateUserSignatureSuccess = success => {
  return {
    type: VALIDATE_USER_SIGNATURE_SUCCESS,
    payload: success,
  }
}

export const getValidateUserSignatureError = error => {
  return {
    type: VALIDATE_USER_SIGNATURE_ERROR,
    payload: error
  }
}

export const validateUserSignatureClearData = () => {
  return {
    type: VALIDATE_USER_SIGNATURE_CLEAR_DATA,
    payload: {}
  };
};
// ****---END---****

// List required signatures for documents
export const getRequiredSignatureDocument = () => {
  return {
    type: REQUIRED_SIGNATURE_DOCUMENTS,
    payload: {},
  };
};

export const getRequiredSignatureDocumentSuccess = success => {
  return {
    type: REQUIRED_SIGNATURE_DOCUMENTS_SUCCESS,
    payload: success,
  }
}

export const getRequiredSignatureDocumentError = error => {
  return {
    type: REQUIRED_SIGNATURE_DOCUMENTS_ERROR,
    payload: error
  }
}

export const requiredSignatureDocumentClearData = () => {
  return {
    type: REQUIRED_SIGNATURE_DOCUMENTS_CLEAR_DATA,
    payload: {}
  };
};
// ****---END---****