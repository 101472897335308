import styled from 'styled-components';
import { colors } from '../Resources/index'
import { makeStyles } from 'tss-react/mui';

export const Text = styled.div`
  position: absolute;
  top: 33px;
  right: 80px;
  color: ${colors.white
    }
`

export const Version = styled.div`
  font-size: 10px;
  position: absolute;
  bottom: 7px;
  right: 7px;
`


export const useStyles = makeStyles()(() => ({
    logoDocPlace: {
        objectFit: 'cover',
        width: '90%',
        position: 'relative',
        top: '-10px'
    },
    Bg: {
        backgroundColor: colors.blue
    },
    color: {
        color: colors.white
    },
    colorBlue: {
        span: {
            color: colors.blue,
            fontWeight: 'normal',
            cursor: 'pointer'
        },
        color: colors.blue
    },
    activeColor: {
        backgroundColor: colors.activeColor + '!important'
    },
    profile: {
        position: 'absolute',
        right: '25px'
    },
    mouseHover: {
        cursor: 'pointer'
    },
    mW: {
        minWidth: '56px'
    }
}))
