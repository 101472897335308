import styled from "styled-components";
import {colors, theme,} from "../../Components/Resources/index";
import {makeStyles} from 'tss-react/mui';

export const LoginFormDocPlace = styled.form`
  width: 100%;
  height: 300px;
  margin-top: 0;
  padding: 30px 12px 12px 8px;
  background-color: ${colors.white};
`;

export const WrapperFormDocPlace = styled.section`
  margin: 0 25px 0 25px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${colors.white};
  background-image: url(${theme.bg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
`;

export const WrapperLogin = styled.div`
  width: 300px;
  height: 100%;
  background-color: ${colors.white};
  float: right;
  overflow: hidden;
  display: block;
  max-width: 300px;
  box-shadow: 1px 0px 13px ${colors.gray};
`;

export const useStyles = makeStyles()(() => ({
    buttons: {
        width: '100%',
        display: 'inline-block',
        height: '40px',
        backgroundColor: "#119949 !important",
        color: '#fff !important'
    },
    userName: {
        marginBottom: '20px',
    },
    imgLogo: {
        width: '100%',
        height: '150px',
        objectFit: 'contain',
        margin: '50% 0px 0px 0px'
    },
    clear: {
        margin: 0,
        padding: 0
    }
}));