import React, { useEffect, useState } from "react";
import { useStyles, Wrapper, WrapperLabeling } from "./style";
import MetaTags from "react-meta-tags";
import TitleDocPlace from "../../../Components/MasterPageInternal/Components/Title/title";
import { Container } from "reactstrap";
import Loading from "../../../Components/loading";
import { DocumentListDocPlace } from "./documentList";
import { connect, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Grid from "@mui/material/Grid";
import ReactRegion from "./RegionSelectP";
import "./styles.css";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import initialDocument from "../../../Assets/initialDocument.png";
import { useNavigate, useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import HeaderForm from "../../../Components/forms_module/tools/header/index";
import ShortTextForm from "../../../Components/forms_module/tools/shortText/index";
import DatePickerForms from "../../../Components/forms_module/tools/datePickerForm/index";
import SelectListForms from "../../../Components/forms_module/tools/selectList/index";
import CheckBox from "../../../Components/forms_module/tools/checkBox/index";
import RadioButtonForm from "../../../Components/forms_module/tools/radioButton/index";
import {
  getData,
  getFormTemplate,
  saveFormCompleted,
  updateData,
  updateDataRequest,
} from "../../../Services/form";

import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export function FormViewer(props) {
  const classes = useStyles();
  const [fieldValues, setFieldValues] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ value: "" });
  const [checkBoxSelect, setCheckBoxSelect] = useState("");
  const [selectOption, setSelectOption] = React.useState([]);
  const [formTemplateJson, setFormTemplateJson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [docTypeID, setDocTypeID] = React.useState("");
  const [regions, setRegions] = React.useState([]);
  const [document, setDocument] = useState("");
  const [startPage] = useState(true);
  const dispatch = useDispatch();
  const [labels, setLabels] = useState([]);
  const [fields, setFields] = useState([]);
  const [current, setCurrent] = useState("Not selected");
  const [currentEmail, setCurrentEmail] = useState("");
  const [documents, setDocuments] = useState([]);
  const [currentDocId, setCurrentDocId] = useState(0);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [deleteOCR, setDeleteOCR] = useState(false);
  const [counter, setCounter] = useState(0);
  const [currentLabelIndex, setCurrentLabelIndex] = useState(0);
  const [theDoc, setTheDoc] = useState({});
  const [updating, setUpdating] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(initialDocument);
  const [saving, setSaving] = useState(true);
  const [currentOrder, setCurrentOrder] = useState(0);
  const [componentsList, setComponentsList] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const navigate = useNavigate();

  const [colorArray] = useState([
    "255, 225, 25",
    "0, 130, 200",
    "230, 25, 75",
    "60, 180, 75",
    "245, 130, 48",
    "145, 30, 180",
    "70, 240, 240",
    "240, 50, 230",
    "210, 245, 60",
    "250, 190, 212",
    "0, 128, 128",
    "220, 190, 255",
    "170, 110, 40",
    "255, 250, 200",
    "128, 0, 0",
    "170, 255, 195",
    "128, 128, 0",
    "255, 215, 180",
    "0, 0, 128",
    "128, 128, 128",
    "255, 255, 255",
    "0, 0, 0",
  ]);

  let params = useParams();

  useEffect(() => {
    if (params.id !== "null" && params.id) {
      getFormTemplate(params.id, (event) => {})
        .then((response) => {
          init(response);
        })
        .catch((error) => {
          toast.error(
            "There is an error loading the document info. Please contact your administrator." +
              error
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/explorer");
    }
  }, [startPage]);

  const init = (responseDocumentPages) => {
    if (params.id) {
      setDocTypeID(params.id);
    }
    if (startPage && responseDocumentPages) {
      //@params props.documentTypeID
      setLoading(true);
      try {
        getData(params.id, (event) => {})
          .then((response) => {
            setFormTemplateJson(response.data);
            if (Object.keys(response.data).length == 0) {
              console.log("No data, then get files.");
              let response = responseDocumentPages.data.pathUrl[0].pages;
              setUpdating(false);
              let sampleFiles = response;
              if (sampleFiles && sampleFiles.length > 0) {
                let newArray = [];

                sampleFiles.map((item) => {
                  newArray.push({
                    thumbnail: item.thumbnail,
                    fileName: item.fileName,
                  });
                });
                let docRegions = new Array(sampleFiles.length).fill([]);
                setAllRegions(docRegions);

                docArray = newArray;

                setDocuments(docArray);
                setDocument(docArray[0].thumbnail);
                setCurrentDocument(docArray[0].thumbnail);
                setRegions([]);
              }

              setUpdating(false);
            } else {
              console.log("Data for this document, has been found.");
              let data = response.data;
              console.log("Data for this document, has been found.");
              //setLabels(response.data.labels)
              setRegions([]);
              setFields(data.labels);
              setComponentsList(data.components);
              setDocument(data.documents[0].thumbnail);
              setCurrentDocument(data.documents[0].thumbnail);

              setCurrent("Not selected");
              setDocuments(data.documents);
              setCurrentDocId(-1);
              setCounter(data.counter);
              setCurrentLabelIndex(data.currentLabelIndex);
              //setTheDoc(data.theDoc);
              setAllRegions(data.allRegions);

              for (var i = 0; i < data.allRegions.length; i++) {
                let obj = data.allRegions[i];

                let newArray = obj.filter(function (el) {
                  return el.type === 1 && el.data.label !== "";
                });
              }
              setUpdating(true);
            }
          })
          .catch((error) => {
            toast.error(
              "There is an error loading the document info. Please contact your administrator." +
                error
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        console.log("No data, then get files.");
        let response = responseDocumentPages.data.pathUrl[0].pages;
        setUpdating(false);
        let sampleFiles = response;
        if (sampleFiles && sampleFiles.length > 0) {
          let newArray = [];

          sampleFiles.map((item) => {
            newArray.push({
              thumbnail: item.thumbnail,
              fileName: item.fileName,
            });
          });
          let docRegions = new Array(sampleFiles.length).fill([]);
          setAllRegions(docRegions);
          docArray = newArray;
          setDocuments(docArray);
          setDocument(docArray[0].thumbnail);
          setCurrentDocument(docArray[0].thumbnail);
          setRegions([]);
        }

        setUpdating(false);
      }
    }
  };

  useEffect(() => {
    try {
      if (currentDocId >= 0) {
        let allRegionsTemp = allRegions;
        allRegionsTemp[currentDocId] = regions;
        setAllRegions(allRegionsTemp);
      }
    } catch (error) {
      console.log("Error creating regions: " + error);
    }
  }, [allRegions, regions]);

  const onChange = (regions) => {
    setRegions(regions);
  };

  const deleteRegionById = (id) => {
    let temp = [...allRegions];
    temp[currentDocId].splice(id, 1); // 2nd parameter means remove one item only
    setAllRegions(temp);
  };

  const selectRegion = (event, id, data, type) => {
    switch (event.detail) {
      case 1: {
        setSaving(false);
        //let regionP = regions[id];
        let regionP = [...regions];

        if (current !== "Not selected" && document !== "") {
          regionP[id].data = {
            label: current,
            email: currentEmail,
            freeText: data.freeText,
            text: regionP[id].data.text,
            boundingBoxes: regionP[id].data.boundingBoxes,
            width: regionP[id].data.width,
            height: regionP[id].data.height,
            regionStyle: regionStylePicker(currentOrder),
          };

          setRegions(regionP);
        } else {
          //toast.warning("To start, select a recipient and a document first.");
        }
        break;
      }
      case 2: {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: "Remove the element?",
          text: "",
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
        }).then((willDelete) => {
          if (willDelete.value) {
            deleteRegionById(id);
          }
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const regionRenderer = (regionProps) => {
    if (!regionProps.isChanging) {
      return <div></div>;
    }
  };

  const regionStyle = {
    background: "rgba(255,255,0, 100%)",

    border: "2px solid rgb(0, 106, 198)",
    borderRadius: "1px",
  };

  const regionStylePicker = (i) => {
    return {
      background: `rgba(${colorArray[i]}, 20%)`,

      border: "2px solid rgb(0, 106, 198)",
      borderRadius: "1px",
    };
  };

  const getDocument = (document, id, doc) => {
    setTheDoc(doc);
    setRegions([]);
    setRegions(allRegions[id]);
    setCurrentDocId(id);
    setDocument(document);
    setCurrentDocument(document);
  };

  const getCoords = (id) => {
    let coords = {};
    let json = [...allRegions];
    json.map((page, idx) => {
      page.map((item) => {
        if (item.id == id) {
          coords = {
            x: item.x,
            y: item.y,
            w: item.width,
            h: item.height,
            page: idx,
          };
        }
      });
    });
    return coords;
  };

  /* Persist data */
  const saveLabels = (docdata, docArray, close, reload) => {
    setLoadingText("Saving data");
    setLoading(true);
    let _documents, _allRegions;
    if (docdata) {
      _documents = docdata;
      _allRegions = docArray;
    } else {
      _documents = [...documents];
      _allRegions = [...allRegions];
    }
    let _document = document;
    if (document == "") {
      setDocument(_documents[0].thumbnail);
      _document = _documents[0].thumbnail;
    }
    let _fields = [];
    componentsList.map((item, idx) => {
      let coords = {};
      //Header
      if (item.type == 1) {
        coords = { x: 0, y: 0, w: 0, h: 0, page: 0 };
      } else {
        coords = getCoords(item.id);
      }
      _fields.push({
        fieldId: idx,
        fieldTitle: item.title,
        fieldType: item.type,
        fieldDescription: item.description,
        fieldRequired: true,
        fieldVisible: item.viewPdf,
        fieldValue: item.value,
        fieldOptions: item.data,
        fieldPosition: coords,
      });
    });

    let form = [
      {
        formId: params.id,
        formTitle: "",
        formDescription: "",
        fields: _fields,
      },
    ];

    let data = {
      region: regions,
      allRegions: _allRegions,
      labels: labels,
      document: _document,
      current: current,
      documents: _documents,
      currentDocId: currentDocId,
      counter: counter,
      currentLabelIndex: currentLabelIndex,
      doc: theDoc,

      components: componentsList,
      form: form,
    };

    if (updating == true) {
      updateData(params.id, data, (event) => {})
        .then((response) => {
          toast.success("Document has been updated");

          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error updating the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);
          if (reload) {
            window.location.reload(false);
          }
        });
    } else {
      updateData(params.id, data, (event) => {})
        .then((response) => {
          setUpdating(true);
          toast.success("Document changes has been saved");

          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error saving the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);

          if (reload) {
            window.location.reload(false);
          }
        });
    }
  };

  var docArray = [];

  const InputChange = (fieldTitle, value) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldTitle]: value,
    }));
  };

  const back = () => {
    navigate("/forms-Requests/");
  };

  const renderForm = (data) => {
    switch (data.fieldType) {
      case 1:
        return (
          <HeaderForm
            type={"view"}
            title={data?.fieldTitle}
            description={data?.fieldDescription}
          />
        );
      case 2:
        return <div>Resultado para caso 2</div>;
      case 3:
        return (
          <DatePickerForms
            isChecked={data.fieldRequired}
            id={data?.fieldId}
            label={data?.fieldTitle}
            type={"view"}
            getDescriptionText={data?.fieldDescription}
            descriptionText={data?.fieldDescription}
            field={selectedDate}
            setField={(valueObj) => {
              setSelectedDate(valueObj);
              InputChange(data.fieldId, valueObj.value);
            }}
            inputFormat={"MM/dd/yyyy"}
          />
        );
      case 4:
        return (
          <ShortTextForm
            isChecked={data.fieldRequired}
            mode={"view"}
            type={"text"}
            label={data?.fieldTitle}
            placeholder={data?.fieldTitle}
            descriptionShortText={data?.fieldDescription}
            getShortText={(v) => {
              InputChange(data.fieldId, v);
            }}
          />
        );
      case 5:
        return (
          <ShortTextForm
            isChecked={data.fieldRequired}
            mode={"view"}
            type={"number"}
            label={data?.fieldTitle}
            placeholder={data?.fieldTitle}
            descriptionShortText={data?.fieldDescription}
            getShortText={(v) => {
              InputChange(data.fieldId, v);
            }}
          />
        );
      case 6:
        return (
          <SelectListForms
            isChecked={data.fieldRequired}
            id={String(data?.fieldId)}
            type="view"
            options={data?.fieldOptions}
            getSelect={(v) => {
              setSelectOption(v);
              InputChange(data.fieldId, v);
            }}
            defaultValue={""}
            selectOption={selectOption}
            variant="filled"
            text={data?.fieldTitle}
            titleSelect={data?.formTitle}
            subTitleSelect={data?.formDescription}
            isMultiple={false}
            required={true}
            error={false}
          />
        );
      case 7:
        return (
          <CheckBox
            isChecked={data.fieldRequired}
            type={"view"}
            required={true}
            checkItem={data?.fieldOptions}
            defaultChecked={false}
            getCheckBoxSelect={(v) => {
              setCheckBoxSelect(v);
              InputChange(data.fieldId, v);
            }}
            checkBoxSelect={checkBoxSelect}
            subTitleCheckBox={data?.fieldDescription}
          />
        );
      case 8:
        return (
          <RadioButtonForm
            isChecked={data.fieldRequired}
            getRadioB={(v) => {
              setCheckBoxSelect(v);
              InputChange(data.fieldId, v);
            }}
            name={"demoView"}
            type={"view"}
            size={"small"}
            disabled={true}
            item={data?.fieldOptions}
            row={true}
            subTitleRadioB={data?.fieldDescription}
          />
        );
      default:
        return <div></div>;
    }
  };

  const completeForm = () => {
    if (formTemplateJson?.form[0]?.fields) {
      const keyObj = Object.keys(fieldValues);
      let totalNotRequired = formTemplateJson?.form[0]?.fields.filter(
        (field) =>
          field.fieldType === 1 ||
          field.fieldType === 2 ||
          field.fieldRequired === false
      );

      let errorSave = false;

      keyObj.forEach((id) => {
        if (!fieldValues[id]) {
          errorSave = true;
        }
      });

      if (
        keyObj.length ===
          formTemplateJson?.form[0]?.fields.length - totalNotRequired.length &&
        !errorSave
      ) {
        const updatedFields = [...formTemplateJson.form[0].fields];

        updatedFields.forEach((row) => {
          if (fieldValues[row.fieldId]) {
            row.fieldValue = fieldValues[row.fieldId];
          }
        });

        const updatedFormTemplate = {
          ...formTemplateJson,
          form: formTemplateJson.form.map((item, index) =>
            index === 0 ? { ...item, fields: updatedFields } : item
          ),
        };

        setFormTemplateJson(updatedFormTemplate);

        updateDataRequest(params.idRequest, updatedFormTemplate, (event) => {})
          .then((response) => {
            toast.success("Document has been updated");
            saveFormCompleted(params.idRequest, (event) => {})
              .then((response) => {
                toast.success("Sent document");
              })
              .catch((error) => {
                toast.error("Error sending completed form: " + error);
              })
              .finally(() => {
                navigate("/forms-Requests");
              });
          })
          .catch((error) => {
            toast.error("Error updating the data: " + error);
          })
          .finally(() => {
            navigate("/forms-Requests");
          });

        toast.success("SAVEEE!");
      } else {
        toast.error("All fields are required!");
      }
      console.log(
        "Cantidad de campos en form",
        formTemplateJson?.form[0]?.fields.length
      );
    } else {
      console.log("no tiene campos");
    }
  };

  return (
    <>
      <Container fluid>
        <Loading show={loading} text={loadingText} />
        <MetaTags>
          <title>Form template view</title>
        </MetaTags>
        <TitleDocPlace title={"Form template"} viewActive={false} />
        <WrapperLabeling>
          <Wrapper>
            <Button
              style={{ padding: "9px 8px", marginLeft: "10px" }}
              className="btnSearchSimple2"
              color="primary"
              onClick={back}
              startIcon={<ArrowBackIosNewIcon />}
            >
              Back
            </Button>
            <IconButton
              color="primary"
              style={{ marginRight: "10px" }}
              title="Save"
              aria-label="Save"
              component="label"
              onClick={() => saveLabels()}
            >
              <SaveIcon />
            </IconButton>
          </Wrapper>
          <Grid container>
            <Grid className={classes.wrapperAside} item xs={4} md={4}>
              <div style={{ padding: "0 20px 20px 20px" }}>
                {formTemplateJson &&
                  formTemplateJson?.form[0]?.fields.map((data, idx) => (
                    <div key={idx}>{renderForm(data)}</div>
                  ))}
              </div>
              <Button
                style={{
                  padding: "9px 8px",
                  marginLeft: "10px",
                  float: "right",
                }}
                variant={"contained"}
                className="btnSearchSimple2"
                color="primary"
                onClick={completeForm}
                endIcon={<SaveIcon />}
              >
                Complete form
              </Button>
            </Grid>
            <Grid
              className={classes.wrapperAside}
              style={{ marginTop: "0px" }}
              item
              xs={8}
              md={8}
            >
              <div className="element">
                <Grid item xs={9} md={9}>
                  <div className="documentShowcase">
                    <ReactRegion
                      maxRegions={1000}
                      regions={regions}
                      onChange={onChange}
                      regionStyle={regionStyle}
                      regionRenderer={regionRenderer}
                      constraint
                      onClick={selectRegion}
                    >
                      <img
                        style={{ width: "100%", pointerEvents: "none" }}
                        src={currentDocument}
                        alt="Current document"
                      />
                    </ReactRegion>
                  </div>
                </Grid>
                <Grid item xs={3} md={3}>
                  <div>
                    <DocumentListDocPlace
                      documents={documents}
                      deleteOCR={deleteOCR}
                      getDocument={getDocument}
                    />
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </WrapperLabeling>
        <ToastContainer />
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingTree: state.loadingFolders,
    tree: state.folders,
  };
};

export default connect(mapStateToProps, {})(FormViewer);
