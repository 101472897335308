import React, {useEffect, useState} from "react";
import {Container} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";

import Title from "../../Components/MasterPageInternal/Components/Title/title";
import "./styles.css";
import {getDocumentPageList} from '../../Store/Document/actions';
import LoadingComponent from "../../Components/loading";
import {getEsignature, getEsignatureData, getIdRequestSignatureByDocumentId} from "../../Services/signature";
import initialDocument from "../../Assets/initialDocument.png";
import {WrapperDocPlace} from "./components/wrapper/style";
import DocViewerVieweSignature from "./view";

let _regions = [];

export function DocViewer(props) {
  let params = useParams();

  const [startPage] = useState(true);
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [enableTrain, setEnableTrain] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(initialDocument);
  const [allRegions, setAllRegions] = useState([]);
  const navigate = useNavigate();

  const { responseDocumentPages, loadingDocumentPages } = useSelector(
    (state) => ({
      responseDocumentPages: state.Document.responseDocumentPages,
      loadingDocumentPages: state.Document.loadingDocumentPages,
    })
  );

  const { responseDocDownload, loadingDocDownload } = useSelector((state) => ({
    responseDocDownload: state.DocViewer.responseDocDownload,
    loadingDocDownload: state.DocViewer.loadingDocDownload,
  }));

  const getDocument = (
    document,
    id,
  ) => {
    _regions = [];
    _regions = [allRegions[id]];
    setCurrentDocument(document);
  };

  const url = responseDocumentPages?.data?.fileReferences.pdf


  const downloadFile = () => {
    if (url){
      window.open(url, '_blank'  );
    }
  }

  useEffect(() => {
    if (responseDocDownload) {
      const blob = new Blob([responseDocDownload], {
        type: "application/octetstream",
      });
      const link = document.createElement("a");
      // create a blobURI pointing to our Blob
      const lastIndex = responseDocDownload?.data?.fkArchive?.name?.lastIndexOf('.');
      const nameSplit = responseDocDownload?.data.fkArchive ? responseDocDownload?.data?.fkArchive?.name?.slice(0, lastIndex) : "";
      link.href = URL.createObjectURL(blob);
      link.download = responseDocDownload?.data.fkArchive
          ? responseDocDownload?.data.fkArchive?.originalExtension
              ? nameSplit + responseDocDownload?.data.fkArchive.originalExtension
              : responseDocDownload?.data.fkArchive?.name
          : "";
      // some browser needs the anchor to be in the doc
      if (link.download) {
        document.body.append(link);
        link.click();
      }
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
  }, [responseDocDownload]);



  useEffect(() => {
    if (params.id !== "null" && params.id) {
      dispatch(getDocumentPageList({ id: params.id }, 1, 10, navigate));
    } else {
      navigate("/home");
    }
  }, [startPage]);

  useEffect(() => {
    if (startPage && responseDocumentPages && responseDocumentPages.data) {
      getEsignature(JSON.parse(localStorage.getItem("esignature")).userId, (event) => { })
      .then(() => {
        getIdRequestSignatureByDocumentId(params.id, (event) => { })
      }).catch((error) => {
        toast.error("There was an error retrieving the signatures. Please contact your administrator." + error)
      })
      getEsignatureData(params.id, (event) => { })
      .then((response) => {
        if (Object.keys(response.data).length === 0) {
          toast.error("There is no available data for this document.")
        } else {
          let data = response.data;
          //setLabels(response.data.labels)
          _regions = [];
          setDocuments(responseDocumentPages?.data?.fileReferences.pages);
          setAllRegions(data.allRegions);
          setCurrentDocument(responseDocumentPages?.data?.fileReferences.pages[0]);
          for (var i = 0; i < data.allRegions.length; i++) {
            let obj = data.allRegions[i];
            let newArray = obj.filter(function (el) {
              return el.type === 1 && el.data.label !== ""
            })
            if (newArray.length > 0) {
              setEnableTrain(true);
              break
            }
          }
        }
      }).catch((error) => {
        toast.error("There is an error loading the document info. Please contact your administrator." + error)
      })
    }
  }, [startPage, responseDocumentPages]);

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Title title={"eSignature Doc Viewer"} viewActive={false} />
            <ToastContainer autoClose={3000} />
            <LoadingComponent show={loadingDocumentPages} text={"Obtaining documents..."}/>
            <LoadingComponent show={loadingDocDownload} text={"Download document..."}/>
            <WrapperDocPlace className="docViewer">
              <DocViewerVieweSignature
                  documents={documents}
                  getDocument={getDocument}
                  currentDocument={currentDocument}
                  downloadFile={downloadFile}
              />
            </WrapperDocPlace>
          </Container>
        </div>
        <Container fluid>
        </Container>
      </React.Fragment>
  );
}