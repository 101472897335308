import http from "./http-common";

export const getDataForm = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/FormTemplateJsonById`;

  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
      customerId: JSON.parse(localStorage.getItem("esignature")).customerId,
      formTemplateId: documentId,
    },
  });
};

export const getData = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/FormTemplateJsonById`;

  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
      customerId: JSON.parse(localStorage.getItem("esignature")).customerId,

      formTemplateId: documentId,
    },
  });
};

export const getFormTemplate = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/GetFormTemplate/${documentId}`;
  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
      customerId: JSON.parse(localStorage.getItem("esignature")).customerId,
    },
  });
};

export const saveFormCompleted = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/forms/ProcessDocumentByRequest/${documentId}`;
  return http.post(
    url,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
        customerId: JSON.parse(localStorage.getItem("esignature")).customerId,
        formTemplateId: documentId, //"04bd4225-fbb6-4d2c-bd6f-3f01adff926f"
      },
    }
  );
};

export const updateData = (documentId, body) => {
  let url =
    process.env.REACT_APP_HOST_API + `/automation/api/v1.0/Forms/UpdateForm`;
  return http.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
      customerId: JSON.parse(localStorage.getItem("esignature")).customerId,
      formTemplateId: documentId, //"04bd4225-fbb6-4d2c-bd6f-3f01adff926f"
    },
  });
};

export const updateDataRequest = (documentId, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/UpdateFormContentRequest`;
  return http.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
      customerId: JSON.parse(localStorage.getItem("esignature")).customerId,
      requestFormID: documentId, //"04bd4225-fbb6-4d2c-bd6f-3f01adff926f"
    },
  });
};

export const getShippedForms2 = (body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/SearchRequestedDocumentsExt?page=1&pageSize=2`;
  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
      customerId: JSON.parse(localStorage.getItem("esignature")).customerId,
    },
  });
};
