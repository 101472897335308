import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TitleDocPlace from "../../../Components/MasterPageInternal/Components/Title/title";
import "./styles.css";
import Loading from "../../../Components/loading";

import initialDocument from "../../../Assets/initialDocument.png";
import { WrapperDocPlace } from "./components/wrapper/style";
import { getShippedForms2 } from "../../../Services/form";
import DocViewerForms from "./view";

export function SeeCompletedForm(props) {
  let params = useParams();

  const [startPage] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(initialDocument);
  const [allRegions, setAllRegions] = useState([]);
  const [dataRoles, setDataRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDocument = (document) => {
    setCurrentDocument(document);
  };

  const url = dataRoles[0]?.pathUrl?.pdf;

  const downloadForm = () => {
    const downloadURL = url;
    const link = document.createElement("a");
    link.href = downloadURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    let body = {
      IsCompleted: true,
      FormTemplateId: params.id,
    };
    setLoading(true);
    getShippedForms2(body, (event) => {})
      .then((response) => {
        setDataRoles(response.data.data);
        setDocuments(response?.data?.data[0].pathUrl.pages);
        setCurrentDocument(response?.data[0].pathUrl.pages[0]);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [startPage]);

  console.log("dataRoles", dataRoles);
  console.log("documents", documents);
  console.log("currentDocument", currentDocument);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TitleDocPlace title={"Completed form view"} viewActive={false} />
          <ToastContainer autoClose={3000} />
          <Loading show={loading} text={"Obtaining documents..."} />
          <Loading show={loading} text={"Download document..."} />
          <WrapperDocPlace className="docViewer">
            <DocViewerForms
              documents={documents}
              getDocument={getDocument}
              currentDocument={currentDocument}
              downloadFile={downloadForm}
            />
          </WrapperDocPlace>
        </Container>
      </div>
      <Container fluid></Container>
    </React.Fragment>
  );
}
