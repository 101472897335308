import React from "react";
import MetaTags from "react-meta-tags";

const AssignedFormsViewDocPlace = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DocPlace forms</title>
        </MetaTags>
      </div>
    </React.Fragment>
  );
};

export default AssignedFormsViewDocPlace;
