import React from "react";
import MetaTags from "react-meta-tags";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

import { DivNav, WrapperForm } from "./style";
import DocumentListDocPlace from "./documentList";

const DocViewerForms = (props) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <MetaTags>
        <title>Completed form view</title>
      </MetaTags>

      <DivNav>
        <Button
          color="primary"
          onClick={() => {
            navigate("/forms-Requests");
          }}
          startIcon={<ArrowBackIosNewIcon />}
          sx={{ textTransform: "none", marginLeft: "25px" }}
        >
          Back
        </Button>
        <Button
          color="primary"
          onClick={props.downloadFile}
          startIcon={<DownloadIcon />}
          sx={{ textTransform: "none", marginRight: "25px" }}
        >
          Download
        </Button>
      </DivNav>
      <WrapperForm>
        <Grid container>
          <Grid className="wrapperAside" item xs={10} md={10}>
            <div className="documentShowcase">
              <img
                style={{ width: "100%", pointerEvents: "none" }}
                src={props.currentDocument}
                alt="Current document"
              />
            </div>
          </Grid>
          <Grid className="wrapperAside" item xs={2} md={2}>
            <DocumentListDocPlace
              documents={props.documents}
              getDocument={props.getDocument}
            />
          </Grid>
        </Grid>
      </WrapperForm>
    </React.Fragment>
  );
};

export default DocViewerForms;
