import {
    API_ERROR_DOC_DELETE,
    API_ERROR_DOC_DOWNLOAD,
    DOC_DELETE_POST,
    DOC_DELETE_POST_SUCCESS,
    DOC_DOWNLOAD_POST,
    DOC_DOWNLOAD_POST_SUCCESS
} from "./actionTypes"

export const postDeleteDoc = (deleteDoc, navigate) => {
    return {
        type: DOC_DELETE_POST,
        payload: { deleteDoc, navigate },
    }
}

export const apiErrorDeleteDoc = error => {
    return {
        type: API_ERROR_DOC_DELETE,
        payload: error,
    }
}

export const postDeleteDocSuccess = deleteDoc => {
    return {
        type: DOC_DELETE_POST_SUCCESS,
        payload: deleteDoc,
    }
}

export const postDocDownload = (download, navigate) => {
    return {
        type: DOC_DOWNLOAD_POST,
        payload: { download, navigate },
    }
}

export const postDocDownloadSuccess = download => {
    return {
        type: DOC_DOWNLOAD_POST_SUCCESS,
        payload: download,
    }
}

export const apiErrorDocDownload = error => {
    return {
        type: API_ERROR_DOC_DOWNLOAD,
        payload: error,
    }
}