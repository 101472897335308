/**GET ALL DOCUMENTS */
export const DOCUMENT_LIST_CLEAR_DATA = "DOCUMENT_LIST_CLEAR_DATA";
export const DOCUMENT_LIST = "DOCUMENT_LIST";
export const DOCUMENT_LIST_SUCCESS = "DOCUMENT_LIST_SUCCESS";
export const DOCUMENT_LIST_ERROR = "DOCUMENT_LIST_ERROR";
/**END */


/**BEGIN stamp esignature */
export const DOCUMENT_PAGES_LIST = "DOCUMENT_PAGES_LIST"
export const DOCUMENT_PAGES_LIST_SUCCESS = "DOCUMENT_PAGES_LIST_SUCCESS"
export const API_ERROR_DOCUMENT_PAGES = "API_ERROR_DOCUMENT_PAGES"

// List require signature for document
export const LIST_REQUIRE_SIGNATURE_DOCUMENT = "LIST_REQUIRE_SIGNATURE_DOCUMENT"
export const LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS = "LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS"
export const LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR = "LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR"
export const LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA = "LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA"
// END

/**END stamp esignature */