import {
    /**Register Esignature external*/
    REGISTER_ERROR,
    REGISTER_SUCCESS,
    REGISTER,
    REGISTER_CLEAR_DATA,
    /**Login Esignature external */
    LOGIN,
    LOGIN_CLEAR_DATA,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
} from "./actionType"

const initialState = {
    /**create user */
    errorRegister: null,
    responseRegister: null,
    loadingRegister: false,
    /**END */

    /* Page login
  list API's*/
    errorLogin: null,
    responseLogin: null,
    loadingLogin: false,
    /* Page login
    END*/
}

const auth = (state = initialState, action) => {
    switch (action.type) {

        /**Create user login  */
        case REGISTER:
            state = {
                ...state,
                errorRegister: null,
                loadingRegister: true,
            }
            break;
        case REGISTER_SUCCESS:
            state = {
                ...state,
                errorRegister: null,
                responseRegister: action.payload,
                loadingRegister: false,
            }
            break;
        case REGISTER_ERROR:
            state = {
                ...state,
                errorRegister: action.payload,
                responseRegister: null,
                loadingRegister: false,
            }
            break;
        case REGISTER_CLEAR_DATA:
            state = {
                ...state,
                errorRegister: null,
                responseRegister: null,
                loadingRegister: false,
            }
            break;
        /**end */
        /* Page login
  list API's*/
        case LOGIN:
            state = {
                ...state,
                errorLogin: null,
                loadingLogin: true,
            }
            break
        case LOGIN_SUCCESS:
            state = {
                ...state,
                errorLogin: null,
                responseLogin: action.payload,
                loadingLogin: false,
            }
            break
        case LOGIN_ERROR:
            state = {
                ...state,
                errorLogin: action.payload,
                responseLogin: null,
                loadingLogin: false,
            }
            break
        case LOGIN_CLEAR_DATA:
            state = {
                ...state,
                errorLogin: null,
                responseLogin: null,
                loadingLogin: false,
            }
            break
        /* Page login
        END*/
        default:
            state = { ...state }
            break;
    }
    return state
}

export default auth;