import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: calc(100vh - 300px);
    overflow-y: auto;
    border-radius: 4px;
    margin-top: 20px;
    display: inline-block;
`;



  



