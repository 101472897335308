const colors = {
    blue: "#006ac6",
    ground: "#eff3f7",
    Title2: "rgb(96, 125, 139)",
    gray: "#607d8b",
    white: "#fff",  
    aqua: "#00cdd7",
    red: "#bb2929",
    mark: "rgb(251, 167, 48)",
    green: "#119949",
    greenHover: "#037432",
    help:"#607d8b3d",
    borderInput: "#2AB57D",
    error: "#bb2929",
    success: "#1ed12d",
    whiteTransparent: "#ffffffcf",
    activeColor:"#1976d22e",
    blueBG:"rgb(33 150 243 / 52%)",
    redBG:"rgb(159 2 2 / 82%)",
    background: "#6EC1E4"
}

const expressions = {
    letterSpacing: /^[a-zA-ZÀ-ÿ\s]{3,40}$/, // Letras y espacios, pueden llevar acentos.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
    SSN: /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
}

const theme = {
    bg: 'https://app.docplace.io/static/media/group-226@2x.34c68d14.png',
    logo: 'https://app.docplace.io/static/media/logo2.c7c1d5fc.svg'
}


export { colors,expressions, theme };