import React, {useEffect, useState} from 'react';
import {Box, Container} from '@mui/material';
import TitleDocPlace from '../../Components/MasterPageInternal/Components/Title/title';
import {documentList} from '../../Store/Document/actions';
import {useDispatch, useSelector} from "react-redux";
import Loading from '../../Components/Resources/loading';
import {ToastContainer} from "react-toastify";
import {DocumentGridDocPlace} from '../../Components/DocumentGrid/grid';
import moment from 'moment/moment';

const DocumentSummary = (props) => {
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const { responseDocumentList, loadingDocumentList, errorDocumentList } = useSelector((state) => ({
        responseDocumentList: state.Document.responseDoumentList,
        loadingDocumentList: state.Document.loadingDocumentList,
        errorDocumentList: state.Document.errorDocumentList
    }));

    useEffect(() => {
        document.title = 'Summary';
        dispatch(documentList());
    }, []);

    useEffect(() => {
        if (responseDocumentList) {
            let rowSpecific = responseDocumentList.data.map((item) => {
                return {
                    id: item.document_id,
                    name: item.document_name,
                    link: item.fileReferences.file,
                    status: signedByMe(item.signers),
                    requested: moment(item.requestDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
                }
            });
            setRows(rowSpecific)

        }
    }, [responseDocumentList]);

    useEffect(() => {
        if (errorDocumentList) {
            console.log(errorDocumentList);
        }

    }, [errorDocumentList])

    /**executed on each row, review if the current row already is signed by me */
    const signedByMe = (signers) => {
        let waitingMe = true;
        let myEmail = JSON.parse(localStorage.getItem("esignature")).email;
        signers.map((item) => {
            if (item.signatory_email === myEmail)
                waitingMe = (item.have_signed === 0) ? false : true;
        });
        return waitingMe;
    }

    return (
        <Container>
            <ToastContainer />
            <Loading show={loadingDocumentList} text="Loading Documents" />
            <TitleDocPlace title={"Your Documents"} viewActive={false} />
            <Container sx={{ marginTop: '30px' }}>
                <Box sx={{ height: '400 px', width: '100%' }}>
                    <DocumentGridDocPlace
                        data={rows ? rows : []}
                    />
                </Box>
            </Container>
        </Container>

    );

}

export default DocumentSummary;
