import {
    /**Get all documents of signature */
    DOCUMENT_LIST,
    DOCUMENT_LIST_SUCCESS,
    DOCUMENT_LIST_ERROR,
    DOCUMENT_LIST_CLEAR_DATA,
    /**Stamp esignature */
    DOCUMENT_PAGES_LIST,
    DOCUMENT_PAGES_LIST_SUCCESS,
    API_ERROR_DOCUMENT_PAGES,
    /**list require esignature documents */
    LIST_REQUIRE_SIGNATURE_DOCUMENT,
    LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA,
    LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR,
    LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS,


} from "./actionType"


/**GET ALL DOCUMENT SIGNATURE OF A GIVEN USER */
export const documentList = (navigate) => {
    return {
        type: DOCUMENT_LIST,
        payload: { navigate },
    }
}

export const documentListSuccess = doc => {
    return {
        type: DOCUMENT_LIST_SUCCESS,
        payload: doc,
    }
}

export const documentListError = error => {
    return {
        type: DOCUMENT_LIST_ERROR,
        payload: error,
    }
}

export const documentListClearData = () => {
    return {
        type: DOCUMENT_LIST_CLEAR_DATA,
        payload: {}
    };
};

/**END Esignature register */

/**BEGIN stamp esignature */
export const getDocumentPageList = (documentPages, page, size, navigate) => {
    return {
        type: DOCUMENT_PAGES_LIST,
        payload: { documentPages, page, size, navigate },
    }
}

export const documentPageSuccess = documentPages => {
    return {
        type: DOCUMENT_PAGES_LIST_SUCCESS,
        payload: documentPages,
    }
}

export const apiErrorDocumentPage = error => {
    return {
        type: API_ERROR_DOCUMENT_PAGES,
        payload: error,
    }
}

// List require signature for document
export const getListRequireSignatureDocument = (id) => {
    return {
      type: LIST_REQUIRE_SIGNATURE_DOCUMENT,
      payload: {id},
    }
  }
  
  export const getListRequireSignatureDocumentSuccess = success => {
    return {
      type: LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS,
      payload: success,
    }
  }
  
  export const getListRequireSignatureDocumentError = error => {
    return {
      type: LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR,
      payload: error
    }
  }
  
  export const listRequireSignatureDocumentClearData = () => {
    return {
      type: LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA,
      payload: {}
    };
  };
  //****---END---****
  
/**END stamp esignature */