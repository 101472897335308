import { call, put, takeEvery } from "redux-saga/effects";
import { getFormsRequestedError, getFormsRequestedSuccess } from "./actions";
import {
  GET_ALL_FORMS_REQUESTED,
  GET_ALL_FORMS_REQUESTED_CLEAR_DATA,
} from "./actionTypes";
import { getAllFormsRequestAPI } from "../../Helpers/call_apis";

// get all forms requested
function* getAllFormsRequested({ payload: { data } }) {
  try {
    const response = yield call(getAllFormsRequestAPI, data);
    yield put(getFormsRequestedSuccess(response));
  } catch (error) {
    yield put(getFormsRequestedError(error));
  }
}

function* getAllFormsRequestedClearData() {
  try {
    yield put(getAllFormsRequestedClearData(true));
  } catch (error) {
    yield put(getFormsRequestedError(error));
  }
}
// ****---END---****
function* authSaga() {
  yield takeEvery(GET_ALL_FORMS_REQUESTED, getAllFormsRequested);
  yield takeEvery(
    GET_ALL_FORMS_REQUESTED_CLEAR_DATA,
    getAllFormsRequestedClearData
  );
}

export default authSaga;
