/**REGISTER ESIGNATURE USER */
export const REGISTER_CLEAR_DATA = "REGISTER_CLEAR_DATA";
export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
/**END */

/* Page login
list API's*/
export const LOGIN_CLEAR_DATA = "LOGIN_CLEAR_DATA"
export const LOGIN = "LOGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"
/* Page login
END*/
