/**Register esignature user */
export const REGISTER_USER_ESIGNATURE = "/security/api/v1.0/esignature/create";
/**END esignature user */

/* Login page
list API's*/
export const POST_LOGIN = "security/api/v1.0/esignature/signIn";
/** */

/**BEGIN documents */
/**Get all  document list */
export const GET_DOCUMENT_LIST =
  "automation/api/v1.0/RequiredEsignature/Documents";
/**END documents */

// Require Signature for the document
export const LIST_REQUIRE_SIGNATURE_DOCUMENT_API =
  "automation/api/v1.0/RequiredEsignature/DocumentSigners";
export const VALIDATE_USER_SIGNATURE =
  "automation/api/v1.0/Signature/User/ValidateUser";
export const GET_REQUIRED_SIGNATURE_API =
  "automation/api/v1.0/RequiredEsignature/Documents"; //REFACTOR needed, this same api, are used in get_document_list
export const RESEND_SIGNATURE_REQUEST_EMAIL =
  "automation/api/v1/RequiredEsignature/SendEmailRequest";

export const GET_DOCUMENT_PAGES = "automation/api/v1.0/Documents/RequestPages";
//END

// DOC VIEWER
export const POST_DELETE_DOC = "automation/api/v1.0/Archives/Delete";
export const POST_DOWNLOAD_DOC = "automation/api/v1.0/Archives";
// END

//AllFormsRequested
export const GET_ALL_FORMS_REQUESTED_API =
  "automation/api/v1.0/Forms/GetAllRequestDocumentUser";
//END AllFormsRequested
