import React, {useEffect, useState} from 'react'
import MetaTags from "react-meta-tags";
import {useNavigate} from "react-router-dom";
import {login} from '../../Store/Auth/actions';
import {LoginFormDocPlace, useStyles, Wrapper, WrapperFormDocPlace, WrapperLogin} from "./style";
import {toast, ToastContainer} from "react-toastify";
import {Container} from "reactstrap";
import logoFD from '../../Assets/logoColorsDocPlace.png';
import InputDocPlace from "../../Components/Form/input";
import {useDispatch, useSelector} from "react-redux";
import {expressions} from "../../Components/Resources/index";
import LoadingComponent from "../../Components/Resources/loading"
import {Button} from "@mui/material";

const Login = () => {
    const { classes } = useStyles();
    const notify = (msj, event) => toast[event](msj);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userName, setUserName] = useState({ value: '', error: false });
    const [password, setPassword] = useState({ value: '', error: false });

    const { errorLogin, responseLogin, loadingLogin } = useSelector(state => ({
        errorLogin: state.Auth.errorLogin,
        responseLogin: state.Auth.responseLogin,
        loadingLogin: state.Auth.loadingLogin
    }))

    /**change the title of the page */
    useEffect(() => {
        document.title = 'Login';
    }, []);

    const setLogin = (e) => {
        e.preventDefault();
        if (!userName.error && !password.error) {   //We allow any email because are external to docplace 
            let obj = {
                email: userName.value,
                password: password.value
            }
            dispatch(login(obj, navigate));

        } else {
            notify('Please enter all the required information', 'error');
        }
    }

    useEffect(() => {
        if (responseLogin) {
            responseLogin.customerId="";  //for esignature we don't have a customer id
            localStorage.setItem("esignature", JSON.stringify(responseLogin));
            navigate('/home');
        }
    }, [responseLogin]);

    useEffect(() => {
        if (errorLogin) {
            notify(errorLogin?.response?.data?.error?.messages[0]?.message, 'error');
        }
    }, [errorLogin]);

    return (
        <React.Fragment>
            <MetaTags>
                <title>Login</title>
            </MetaTags>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <ToastContainer autoClose={3000} />
                    <LoadingComponent show={loadingLogin} text={"Login to DocPlace..."} />
                    <Wrapper>
                        <WrapperLogin>
                            <img alt="docplace" className={classes.imgLogo} src={logoFD} />
                            <LoginFormDocPlace onSubmit={setLogin}>
                                <WrapperFormDocPlace>
                                    <InputDocPlace
                                        id={"username"}
                                        field={userName}
                                        setField={setUserName}
                                        required={true}
                                        type="text"
                                        label="Username"
                                        expresionRegular={expressions.email}
                                    />
                                    <InputDocPlace
                                        id={"password"}
                                        field={password}
                                        setField={setPassword}
                                        required={true}
                                        type="password"
                                        label="Password"
                                    />
                                    {
                                        <section>
                                            <Button type="submit" className={classes.buttons} variant="contained"
                                                    color="success">
                                                Sign in
                                            </Button>
                                        </section>
                                    }
                                </WrapperFormDocPlace>
                            </LoginFormDocPlace>
                        </WrapperLogin>
                    </Wrapper>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Login