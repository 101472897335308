import React from "react";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  ContainerTextField,
  DivHelperTextTitle,
  useStyles,
  WrapperTextShort,
} from "./style";
import { colors } from "../../../theme";

const ShortTextViewDocPlace = (props) => {
  const classes = useStyles();
  return (
    <WrapperTextShort>
      {props.mode === "view" ? (
        <>
          <DivHelperTextTitle>{props.helperShortText}</DivHelperTextTitle>
          <TextField
            id="shortText"
            type={props.type}
            label={props.label}
            placeholder={props.placeholder}
            variant="outlined"
            fullWidth={true}
            disabled={props.disable}
            required={true}
            onChange={props.onChangeShortText}
            InputProps={{}}
          />
          <section className={classes.subtitle}>
            {props.isChecked && <div style={{ color: "red" }}>*Mandatory</div>}
            {props.descriptionShortText}
          </section>
        </>
      ) : (
        <>
          <Container>
            <ButtonContainer>
              <Button
                variant="text"
                onClick={props.onChangeNotSeeIntoPDF}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notSeeIntoPDF && colors.gray3,
                }}
                startIcon={<VisibilityOffIcon />}
              >
                Not see into PDF
              </Button>
            </ButtonContainer>
            <ContainerTextField>
              <TextField
                id="helpTextTitle"
                placeholder="Click to Enter Title"
                fullWidth={true}
                variant="standard"
                onChange={props.onChangeHelpText}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                  },
                }}
              />
            </ContainerTextField>
            <TextField
              id="shortText"
              label={props.label}
              disabled={props.disabled}
              variant="outlined"
              fullWidth={true}
              required={true}
              onChange={props.onChangeShortText}
              InputProps={{}}
            />
            <ContainerTextField>
              <TextField
                id="helpTestDescription"
                placeholder="Click to Enter Description"
                fullWidth={true}
                variant="standard"
                onChange={props.onChangeDescriptionHelText}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "lighter",
                  },
                }}
              />
            </ContainerTextField>
            <ButtonContainerTools>
              <Button
                onClick={props.onChangeSettingShortText}
                style={{
                  textTransform: "none",
                  marginRight: "20px",
                }}
                variant={"text"}
                startIcon={<SettingsSuggestIcon />}
              >
                Settings
              </Button>
              <Button
                onClick={props.onChangeDeleteShortText}
                style={{
                  textTransform: "none",
                }}
                color="error"
                variant={"text"}
                startIcon={<DeleteForeverIcon />}
              >
                Delete
              </Button>
            </ButtonContainerTools>
          </Container>
        </>
      )}
    </WrapperTextShort>
  );
};
export default ShortTextViewDocPlace;
