import React, { useState } from "react";
import { Box, Button, Grid, Checkbox, Modal, Link } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import InputFormDocPlace from "../../../Components/Form/input";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '350px',
    bgcolor: 'background.paper',
    justifyContent: "center",
    p: 4,
};

const divSign = {
    border: '1px solid #1976d2',
    width: '405px',
    height: '175px',
    justifyContent: 'center'
};


let sigPad = {};   //reference for the signpad, and expose their methods

const Step3 = (props) => {
    const [acceptTerms, setAccept] = useState(props.data.tycAccepted);
    const [open, setOpen] = useState(false);
    const [signText, setText] = useState({ value: props.data.signText, error: false });
    const navigate = useNavigate();
    let ctx = null;

    /**Clear the canvas */
    const clearPad = () => {
        sigPad.clear();
    }

    /*draw the text on the canvas */
    const writeText = () => {
        if (signText.value.length > 0) {
            sigPad.clear();  //clear completely the canvas
            ctx = sigPad.getCanvas().getContext("2d");
            let positionx = Math.round((405 - (15 * (signText.value.length))) / 2);
            const { text, x, y } = { text: signText.value, x: positionx, y: 80 };
            ctx.beginPath();
            ctx.font = "40px AlexBrush";  //use custom font for the handwriting 
            ctx.textAlign = 'left';
            ctx.textBaseline = 'top';
            ctx.fillStyle = 'black';
            ctx.fillText(text, x, y);
            ctx.stroke();
        }
    }

    /**executes when the saved button is clicked. converts the image to 64 string based and execute the parent dispatch to API to create user
    */
    const saveMethod = () => {
        if (acceptTerms && (!sigPad.isEmpty() || signText !== "")) {
            props.setData({
                tycAccepted: acceptTerms,
                sign: sigPad.getTrimmedCanvas().toDataURL(),
                signText: signText,
                rawSign: sigPad.getTrimmedCanvas().toDataURL("image/png"),
            });
            props.createUser();
        }
        else
            toast.error("You need to accept the term and conditions and provide a signature");
    }

    /**When close, if there is information in the input will draw the signature in the canvas */
    const onClose = () => {
        writeText();
        setOpen(false);
    }

    /**Open a new tab with the terms and conditions */
    const openTyC = () => {
        window.open("https://www.docplace.io/terms-and-conditions/");
    }

    /**Back to previous page */
    const backPrevious = () => {
        navigate("/");
    }

    return (
        <Box>
            <Box
                textAlign="center"
                sx={{ margin: '10px' }}>
                Please draw your signature in the box below or click on the button for Docplace
                generate one automatically from your name
            </Box>
            <Box display="flex"
                justifyContent="center"
                alignItems="center">
                <div style={divSign}>
                    <SignatureCanvas penColor='black'
                        canvasProps={{ width: "405px", height: "175px" }} ref={(ref) => { sigPad = ref }} />
                </div>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Button variant="contained" sx={{ mt: 2, mr: 2 }} onClick={clearPad}>
                            Clear
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" sx={{ mt: 2, mr: 2, float: "right" }}
                            onClick={() => { setOpen(true) }} >
                            Create from name
                        </Button>
                    </Grid>
                    <Grid item xs={11} >
                        I confirm that I agree with the <Link href="#" onClick={openTyC}>terms & conditions </Link> of DocPlace
                    </Grid>
                    <Grid item xs={1}>
                        <Checkbox
                            checked={acceptTerms}
                            onChange={(e) => { setAccept(e.target.checked); }}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={backPrevious} sx={{ mt: 2 }}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" sx={{ mt: 2, mr: 2, float: "right" }} onClick={saveMethod} color="success" >
                            Register
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={() => { onClose() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={boxStyle}
                    alignItems="center"
                    justifyContent="center" >
                    <Box>
                        <h3>Signature</h3>
                        <InputFormDocPlace
                            id="textSign"
                            field={signText}
                            setField={setText}
                            label="Write your name"
                        />
                        <Button variant="contained" color="success" onClick={onClose} sx={{ marginTop: "10px" }}>
                            Draw Signature
                        </Button>
                        {/**div to preload the font, if not preload the first time the canvas will not write with the custom canvas.
                         */}
                        <div style={{ font: '5px AlexBrush', color: "white" }}>.</div>

                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default Step3;
