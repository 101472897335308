import {
    API_ERROR_DOC_DELETE,
    API_ERROR_DOC_DOWNLOAD,
    DOC_DELETE_POST,
    DOC_DELETE_POST_SUCCESS,
    DOC_DOWNLOAD_POST,
    DOC_DOWNLOAD_POST_SUCCESS,
} from "./actionTypes";

const initialState = {
    errorDeleteDoc: null,
    responseDeleteDoc: null,
    loadingDeleteDoc: false,

    errorDocDownload: null,
    responseDocDownload: null,
    loadingDocDownload: false,
};

const DocViewer = (state = initialState, action) => {
    switch (action.type) {
        case DOC_DELETE_POST:
            state = {
                ...state,
                errorDeleteDoc: null,
                loadingDeleteDoc: true,
            };
            break;
        case DOC_DELETE_POST_SUCCESS:
            state = {
                ...state,
                errorDeleteDoc: null,
                responseDeleteDoc: action.payload,
                loadingDeleteDoc: false,
            };
            break;
        case API_ERROR_DOC_DELETE:
            state = {
                ...state,
                errorDeleteDoc: action.payload,
                loadingDeleteDoc: false,
                responseDeleteDoc: null,
            };
            break;
        case DOC_DOWNLOAD_POST:
            state = {
                ...state,
                errorDocDownload: null,
                loadingDocDownload: true,
            };
            break;
        case DOC_DOWNLOAD_POST_SUCCESS:
            state = {
                ...state,
                errorDocDownload: null,
                responseDocDownload: action.payload,
                loadingDocDownload: false,
            };
            break;
        case API_ERROR_DOC_DOWNLOAD:
            state = {
                ...state,
                errorDocDownload: action.payload,
                loadingDocDownload: false,
                responseDocDownload: null,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default DocViewer;