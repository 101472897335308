import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputDocPlace from "../../../Components/Form/input";
import SelectDropdownDocPlace from "../../../Components/Form/select";
import DatePickerDocPlace from "../../../Components/Form/datePicker";
import { expressions } from "../../../Components/Resources/index";
import { useNavigate } from "react-router-dom";
import { contries } from "../dto";

const Step1 = (props) => {
  const passwordErrorMessage =
    "Your new password must be at least 8 characters lenght and contain uppercase, lowercase characters and at least one number";
  const emailErrorMessage = "Must be a valid email address";
  const countries = contries; //consume API in the future
  const [fullName, setFullName] = useState({ value: props.data.fullName }); //add full name
  const [nationality, setNationality] = useState({
    value: props.data.nationality,
  });
  const [dateBirth, setBirth] = useState(props.data.dateBirth);
  const [email, setEmail] = useState({
    value: props.data.email,
    error: false,
    errorMessage: "",
  });
  const [emailReview, setEmailReview] = useState({
    value: props.data.confirmEmail,
    error: false,
    errorMessage: "",
  });
  const [password, setPassword] = useState({
    value: props.data.password,
    error: false,
    errorMessage: "",
  });
  const [passwordConfirm, setPasswordConfirm] = useState({
    value: props.data.confirmPassword,
    error: false,
    errorMessage: "",
  });
  const [nationalityList] = useState(countries);
  const [SSN, setSSN] = useState({ value: props.data.SSN, error: false });
  const navigate = useNavigate();
  const emailValidation = () => {
    //if everything is good, return true
    if (!email.value || !emailReview.value) return; // exit early if either email or emailReview is empty
    const validInfo =
      expressions.email.test(email.value) &&
      expressions.email.test(emailReview.value); //true if both inputs are correct
    const noError = email.value === emailReview.value; //true if they are ok
    setEmailReview({
      ...emailReview,
      error: !(noError && validInfo),
      errorMessage: noError ? "" : "The email and confirmation don't match",
    });
    return noError && validInfo;
  };

  const passwordValidation = () => {
    //if everything is good, return true
    if (!password.value || !passwordConfirm.value) return; // exit early if either password or passwordConfirm is empty
    const validInfo =
      expressions.password.test(password.value) &&
      expressions.password.test(passwordConfirm.value); //true if both are correct
    const noError = password.value === passwordConfirm.value; //true if they are ok
    setPasswordConfirm({
      ...passwordConfirm,
      error: !(noError && validInfo),
      errorMessage: noError ? "" : "The password and confirmation don't match",
    });
    return noError && validInfo;
  };

  const handleSubmit = (e) => {
    //review with all the values if they don't have issues
    let emailCheck = emailValidation();
    let passwordCheck = passwordValidation();
    if (dateBirth !== "" && emailCheck && passwordCheck && !SSN.error) {
      //dispatch the api and go to the next step
      props.setData({
        fullName: fullName.value,
        nationality: nationality.value,
        dateBirth: dateBirth,
        email: email.value,
        confirmEmail: emailReview.value,
        password: password.value,
        confirmPassword: passwordConfirm.value,
        SSN: SSN.value,
      });
      props.next();
    } else {
      //error
      toast.error("Some validations in the form didn't pass");
    }
    e.preventDefault();
  };

  /**Back to previous page */
  const backPrevious = () => {
    navigate("/");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box textAlign="center" sx={{ margin: "10px" }}>
        Let's start by creating an account for you
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} rowSpacing={3}>
          <Grid item xs={12}>
            <InputDocPlace
              id="fname"
              label="Full Name"
              field={fullName}
              setField={setFullName}
              type="text"
              required={true}
              expresionRegular={expressions.letterSpacing}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectDropdownDocPlace
              id={"nationalityL"}
              field={nationality}
              setField={setNationality}
              defaultList={nationality.value}
              required={true}
              label="Nationality"
              arrayList={nationalityList}
              isMulti={false}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerDocPlace
              field={dateBirth}
              setField={setBirth}
              label="Date of Birth"
              inputFormat="MM/DD/YYYY"
            />
          </Grid>
          <Grid item xs={6}>
            <InputDocPlace
              id="emailD"
              field={email}
              setField={setEmail}
              type="email"
              label="Email address"
              helperText={email.errorMessage}
              errorMessage={emailErrorMessage}
              expresionRegular={expressions.email}
              disabled={props.isFromDocPlace}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <InputDocPlace
              id="emailConfirm"
              field={emailReview}
              setField={setEmailReview}
              type="email"
              label="Confirm email"
              errorMessage={emailErrorMessage}
              helperText={emailReview.errorMessage}
              expresionRegular={expressions.email}
              disabled={props.isFromDocPlace}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <InputDocPlace
              id="passwordD"
              field={password}
              setField={setPassword}
              type="password"
              label="Password"
              helperText={password.errorMessage}
              errorMessage={passwordErrorMessage}
              expresionRegular={expressions.password}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <InputDocPlace
              id="passwordConfirm"
              field={passwordConfirm}
              setField={setPasswordConfirm}
              type="password"
              label="Confirm password"
              errorMessage={passwordErrorMessage}
              helperText={passwordConfirm.errorMessage}
              expresionRegular={expressions.password}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="error"
              onClick={backPrevious}
              sx={{ mt: 2 }}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              sx={{ marginTop: "20px", float: "right" }}
              startIcon={<AddTaskIcon />}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Step1;
