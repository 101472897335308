import React from "react";
import { Wrapper } from "./style";
import { Button, Chip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import TimerIcon from "@mui/icons-material/Timer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import baseSignature from "../../Assets/baseSignature.png";

export function DocumentGridDocPlace(props) {
  const headCols = [
    {
      field: "requested",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Requested Date",
    },
    {
      field: "name",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Name",
    },
    {
      field: "status",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Status",
      renderCell: (params) => (
        <>
          <div>
            <Chip
              icon={params.row.status ? <CheckCircleIcon /> : <TimerIcon />}
              label={params.row.status ? "Signed" : "Waiting signature"}
              variant="outlined"
            />
          </div>
        </>
      ),
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      headerClassName: "MuiTableHead-root",
      flex: 1,
      renderCell: (params) => (
        <>
          {!params.row.status && (
            <Button
              title="Sign"
              onClick={() =>
                (window.location.href = "/stampSignature/" + params.row.id)
              }
            >
              <img
                src={baseSignature}
                alt="Sign"
                style={{ width: "28px", height: "28px", marginRight: "10px" }}
              />
              <span style={{ marginLeft: "10px" }}>Sign</span>
            </Button>
          )}
          {params.row.status && (
            <Button
              title="Sign"
              onClick={() =>
                (window.location.href = "/docViewer/" + params.row.id)
              }
            >
              <img
                src={baseSignature}
                alt="Sign"
                style={{ width: "28px", height: "28px", marginRight: "10px" }}
              />
              <span style={{ marginLeft: "10px" }}>View</span>
            </Button>
          )}
        </>
      ),
    },
  ];
  return (
    <Wrapper>
      <DataGrid
        rows={props.data}
        columns={headCols}
        pageSize={10}
        rowsPerPageOptions={[5]}
        sortingOrder={["asc", "desc"]}
        getRowClassName={() => `MuiTableCell-row`}
        initialState={{
          sorting: {
            sortModel: [{ field: "requested", sort: "desc" }],
          },
        }}
        sx={{
          ".MuiTableHead-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Wrapper>
  );
}

export default DocumentGridDocPlace;
