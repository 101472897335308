import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const Wrapper = styled.div`
  width: 100%;
  height: 59px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 20px;
`;

export const useStyles = makeStyles((theme) => ({
  iconSearch: {
    margin: "19px 16px 0",
    position: "absolute",
    color: "#006ac6",
  },
  buttonSearch: {
    right: "14px",
    top: "16px",
    color: "rgb(251, 167, 48)",
    position: "absolute",
    cursor: "pointer",
  },
  input: {
    height: "40px",
    paddingTop: "18px",
  },
  baseSearch: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "4px",
    position: "relative",
    height: "58px",
  },
  inputSearch: {
    width: "100%",
    height: "39px",
  },
}));
