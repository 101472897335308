import React from "react";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import {Container} from './style'

export const HeaderDocPlaceBf = (props) => {
    const [contextMenu, setContextMenu] = React.useState(null);
    const open = Boolean(contextMenu);
    const openMenu = (event) => {
        setContextMenu(event.currentTarget);
    };
    const closeMenu = () => {
        setContextMenu(null);
    };

    return (
        <header>
            <React.Fragment>
                <Container>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}>
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={openMenu}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <AccountCircleIcon style={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Container>
                <Menu
                    anchorEl={contextMenu}
                    id="account-menu"
                    open={open}
                    onClose={closeMenu}
                    onClick={closeMenu}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={props.Logout.bind(this)}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </React.Fragment>
        </header>
    );
}

export default HeaderDocPlaceBf