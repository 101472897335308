import http from "./http-common";

/**UPLOAD signature image PNG */
export const uploadSignature = (file, name, email, onUploadProgress) => {
    let formData = new FormData();
    let url = process.env.REACT_APP_HOST_API + '/automation/api/v1.0/Signature/User/'+JSON.parse(localStorage.getItem("esignature")).userId;
    formData.append("file", file, "image.png");  //upload the blob image
    formData.append("type","EXTERNAL");
    formData.append("acceptTermsAndConditions","1")
    formData.append("name",name);
    formData.append("email",email);

    return http.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("esignature")).token,
            "customerId": "",
            "doctypeId": "",
            "userId": JSON.parse(localStorage.getItem("esignature")).userId 
        },
        onUploadProgress,
    });
};
