import { combineReducers } from "redux";
import Auth from "./Auth/reducer";
import Document from "./Document/reducer";
import UserValidateSignature from "./ESignature/reducer";
import DocViewer from "./DocViewer/reducer";
import AllFormsRequested from "./Forms/reducer";

const rootReducer = combineReducers({
  Auth,
  Document,
  UserValidateSignature,
  DocViewer,
  AllFormsRequested,
});

export default rootReducer;
