import { call, put, takeEvery } from "redux-saga/effects"
import { 
    /**register external esignature user */
    REGISTER_CLEAR_DATA, 
    REGISTER,
    /**Login external esignature user */
    LOGIN, 
    LOGIN_CLEAR_DATA 
} from "./actionType"
import { 
    /**register external esignature user */
    registerError, 
    registerSuccess, 
    registerClearData,
    /**login external esignature user */ 
    loginError, 
    loginSuccess
} from "./actions"
import { postRegisterUser, postLogin } from "../../Helpers/call_apis"

/**Esignature external user register */
function* registerClearDataEsignature() {
    try {
        debugger;
        yield put(registerClearData())
    } catch (error) {
        debugger;
        yield put(registerError(error))
    }
}

function* registerEsignatureUser({ payload: { user, navigate } }) {
    try {
        const response = yield call(postRegisterUser, user)
        yield put(registerSuccess(response));
    } catch (error) {
        console.log(error);
        yield put(registerError(error));
    }
}


/**External esignature user login */
function* login({ payload: { user, navigate } }) {
    try {
        const response = yield call(postLogin, user)
        yield put(loginSuccess(response))
    } catch (error) {
        yield put(loginError(error));
        navigate('/');
    }
}

function* loginClearData() {
    try {
        yield put(loginClearData(true))
    } catch (error) {
        yield put(loginError(error))
    }
}

function* authSaga() {
    /**External esignature register */
    yield takeEvery(REGISTER_CLEAR_DATA, registerClearDataEsignature);
    yield takeEvery(REGISTER, registerEsignatureUser);
    /**External esignature user login */
    yield takeEvery(LOGIN, login);
    yield takeEvery(LOGIN_CLEAR_DATA, loginClearData);
}


export default authSaga
