import React, {useEffect, useState} from "react";
import {LiBack, LiDelete, Nav, Ul, Wrapper, WrapperLabeling} from "./style";
import Title from "../../Components/MasterPageInternal/Components/Title/title";
import {Container} from "reactstrap";
import Loading from "../../Components/Resources/loading";
import {DocumentListDocPlace} from "./documentList";
import {connect, useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import ReactRegion from "./RegionSelectP";
import "./styles.css";
import FingerPrintIcon from "@mui/icons-material/BorderColor";
import initialDocument from "../../Assets/initialDocument.png";
import {useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";

import {
  getEsignature,
  getEsignatureData,
  getIdRequestSignatureByDocumentId,
  saveLabeledData,
  SignDocumentByRequest,
  updateLabeledData
} from "../../Services/signature";
import {toast, ToastContainer} from "react-toastify";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {getDocumentPageList} from '../../Store/Document/actions';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  bgcolor: "background.paper",
  p: 4,
};

let firstLoad = true;
let running = false;
let _regions = [];

export function StampSignature(props) {
  const [loading, setLoading] = useState(true);
  const [docTypeID, setDocTypeID] = React.useState("");
  const [regions, setRegions] = React.useState([]);
  const [document, setDocument] = useState("");
  const [startPage] = useState(true);
  const dispatch = useDispatch();
  const [labels, setLabels] = useState([]);
  const [fields, setFields] = useState([]);
  const [current, setCurrent] = useState("Not selected");
  const [documents, setDocuments] = useState([]);
  const [currentDocId, setCurrentDocId] = useState(0);
  const [loadingText, setLoadingText] = useState("Loading documents");
  const [deleteOCR, setDeleteOCR] = useState(false);
  const [counter, setCounter] = useState(0);
  const [currentLabelIndex, setCurrentLabelIndex] = useState(0);
  const [enableTrain, setEnableTrain] = useState(false);
  const [hasSignature, setHasSignature] = useState(false);
  const [order, setOrder] = useState(false);
  const [theDoc, setTheDoc] = useState({});
  const [updating, setUpdating] = useState(false);
  const [showSigns, setShowSigns] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(initialDocument);
  const [saving, setSaving] = useState(true);
  const [currentRegion, setCurrentRegion] = useState(0);
  const [signature, setSignature] = useState("");
  const [hashSignature, setHashSignature] = useState("");
  const [ssid, setSSID] = useState("");

  const styleDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    bgcolor: "background.paper",
    p: 4,
  };

  const [currentOrder, setCurrentOrder] = useState(0);
  //Contains all regions
  const [allRegions, setAllRegions] = useState([]);
  const navigate = useNavigate();

  const { responseDocumentPages } = useSelector(
    (state) => ({
      responseDocumentPages: state.Document.responseDocumentPages,
    })
  );

  let params = useParams();

  useEffect(() => {
    if (params.id !== "null" && params.id) {
      dispatch(getDocumentPageList({ id: params.id }, 1, 10, navigate));
    } else {
      navigate("/home");
    }
  }, [startPage]);

  useEffect(() => {
    if (params.id) {
      setDocTypeID(params.id);
    }
    if (startPage && responseDocumentPages && responseDocumentPages.data) {

      //@params props.documentTypeID
      setLoading(true);
      getEsignature(JSON.parse(localStorage.getItem("esignature")).userId, (event) => { })
        .then((response) => {
          setSignature(response.data.data[0].path)
          setHashSignature(response.data.data[0].id)


          getIdRequestSignatureByDocumentId(params.id, (event) => { })
            .then((response) => {
              console.log("response sssid", response.data.id)
              setSSID(response.data.id)
            }).catch((error) => {
              toast.error("There was an error retrieving the request signature id. Please contact your administrator." + error)
            })
        }).catch((error) => {
          toast.error("There was an error retrieving the signatures. Please contact your administrator." + error)
        })

      getEsignatureData(params.id, (event) => { })
        .then((response) => {
          if (Object.keys(response.data).length === 0) {
            setLoading(false)
            console.log("No data");
            toast.error("There is no available data for this document.")
            setUpdating(false);
          } else {
            console.log("Data for this document, has been found.")
            let data = response.data;
            //setLabels(response.data.labels)
            setRegions([]);
            _regions = [];
            setFields(data.labels);
            setDocument(data.documents[0].thumbnail);
            setCurrentDocument(data.documents[0].thumbnail);
            setCurrent("Not selected");
            setDocuments(data.documents);
            setCurrentDocId(-1);
            setCounter(data.counter);
            setCurrentLabelIndex(data.currentLabelIndex);
            //setTheDoc(data.theDoc);
            setAllRegions(data.allRegions);
            setCurrentDocId(0);
            let enable = false;
            let tempDocs = data.documents;

            for (var i = 0; i < data.allRegions.length; i++) {
              let obj = data.allRegions[i];

              let newArray = obj.filter(function (el) {
                return el.type === 1 && el.data.label !== ""
              })
              if (newArray.length > 0) {
                setEnableTrain(true);
                break
              }
            }

            setUpdating(true);
          }
        }).catch((error) => {
          toast.error("There is an error loading the document info. Please contact your administrator." + error)

        })
        .finally(() => {
          setLoading(false);
        });
      firstLoad = false;

    }
  }, [startPage, responseDocumentPages]);

  useEffect(() => {
    try {
      _regions = [...regions];
      if (currentDocId >= 0) {
        let allRegionsTemp = allRegions;
        allRegionsTemp[currentDocId] = regions;
        setAllRegions(allRegionsTemp);
      }
    } catch (error) {
      console.log("Error creating regions from OCR: " + error);
    }
  }, [allRegions, regions]);



  function checkRequestSignature() {
    let electronicMail = JSON.parse(localStorage.getItem("esignature")).email;
    let result = false;
    let temp = [...allRegions];
    let validArray = [];
    let lock = 0;
    let locked = false;

    for (var i = 0; i < temp.length; i++) {
      let xm = temp[i]
      for (var x = 0; x < xm.length; x++) {
      
        if (xm[x].data.email === electronicMail) {
          if ((xm[x].data.text).includes("esignature #")) {
            console.log("Found a signature stamp.", xm[x].data.text)
            lock++;
            locked = true;
            if (xm[x].data.signed && xm[x].data.signed === true)
              validArray.push(electronicMail)
          }
        }
      }

    }

    result = (lock === validArray.length) && locked === true
    return result
  }

  const onChange = (regions) => {
    _regions = [...regions];
    setRegions(regions);
  };

  const selectRegion = (event, id, data, type) => {
    var regionP = [...regions];
    switch (event.detail) {
      case 1: {
        if (type === 1) {
          setShowSigns(true)
          setCurrentRegion(id)
          setHasSignature(regionP[id].data.image !== null || regionP[id].data.image || regionP[id].data.image !== undefined)
        }

        break;
      }
      case 2: {

        break;
      }

      default: {
        break;
      }
    }



  };


  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
  const requestEsignature = () => {
    setLoadingText("Requesting eSignature");
    if (checkRequestSignature() === true) {
      setLoading(true);
      SignDocumentByRequest(ssid, (event) => { })
        .then((response) => {
          toast.success('The document has been signed.')
        })
        .catch((error) => {
          toast.error("Error signing the document: " + error);
        })
        .finally(() => {
          setLoading(false);
          navigate("/home");
        });
    } else {
      toast.warning("You must fill all  signatures in the document.")
    }
  }



  const regionRenderer = (regionProps) => {
    if (!regionProps.isChanging) {
      return <div></div>;
    }
  };



  const regionStyleStart = {
    background: "rgba(255,255,255, 20%)",
    border: "1px solid black",
    borderRadius: "10px"
  };
  const regionStyle = {
    background: "rgba(255,255,0, 80%)",

    border: "1px solid black",
    borderRadius: "10px"
  };




  const getDocument = (
    document,
    id,
    fileName,
    filePath,
    ocr,
    doc,
    fileOriginalName
  ) => {
    setTheDoc(doc);

    setRegions([]);
    _regions = [];
    setRegions(allRegions[id]);
    _regions = [allRegions[id]];
    setCurrentDocId(id);
    setDocument(document);
    setCurrentDocument(document);
  };

  const stampSignature = (remove = false) => {

    let id = currentRegion
    let regionP = [...regions];
    if (remove === true) {
      regionP[id].data = {
        image: null,
        signed: false,
        hash: null,
        label: regionP[id].data.label,
        email: regionP[id].data.email,
        freeText: regionP[id].data.freeText,
        text: regionP[id].data.text,
        boundingBoxes: regionP[id].data.boundingBoxes,
        width: regionP[id].data.width,
        height: regionP[id].data.height,
        regionStyle: regionStyle,
      };
    } else {
      regionP[id].data = {
        image: signature,
        signed: true,
        hash: hashSignature,
        label: regionP[id].data.label,
        email: regionP[id].data.email,
        freeText: regionP[id].data.freeText,
        text: regionP[id].data.text,
        boundingBoxes: regionP[id].data.boundingBoxes,
        width: regionP[id].data.width,
        height: regionP[id].data.height,
        regionStyle: regionStyle,
      };
    }
    setRegions(regionP);
    _regions = [...regionP];
    setShowSigns(false)
  }

  /* Persist data */
  const saveLabels = (docdata, docArray, close, reload) => {

    setLoadingText("Saving data");
    setLoading(true);
    let _documents, _allRegions;
    if (docdata) {
      _documents = docdata;
      _allRegions = docArray;
    } else {
      _documents = [...documents];
      _allRegions = [...allRegions];
    }

    let _document = document;

    if (document === "") {
      setDocument(_documents[0].thumbnail);
      _document = _documents[0].thumbnail;
    }
    let data = {
      region: regions,
      allRegions: _allRegions,
      labels: labels,
      document: _document,
      current: current,
      documents: _documents,
      currentDocId: currentDocId,
      counter: counter,
      currentLabelIndex: currentLabelIndex,
      doc: theDoc,
    };
    if (updating === true) {
      updateLabeledData(params.id, data, (event) => { })
        .then((response) => {
          //toast.success("Document has been updated");

          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error updating the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);
          if (reload) {
            window.location.reload(false);
          }
        });
    } else {
      saveLabeledData(params.id, data, (event) => { })
        .then((response) => {
          setUpdating(true);
          toast.success("Document changes has been saved");

          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error saving the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);
          checkRequestSignature();
          if (reload) {
            window.location.reload(false);
          }
        });
    }
  };


  return (
    <>
      <Loading show={loading} text={loadingText} />
      <Modal
        open={showSigns}
        onClose={() => setShowSigns(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add your signature
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action will place your signature in the selected area. <br />
          </Typography>
          <div alt="sign1" style={{ width: "100%", height: "200px", textAlign: "center" }}>
            <img alt="signature" style={{ width: "100%", height: "200px", textAlign: "center", cursor: "pointer" }} src={signature} />
          </div>

          <Button
            variant="contained"
            color="success"
            onClick={() => stampSignature()}
            sx={{ mt: 2, mr: 2, float: "left" }}
          >
            Add
          </Button>
          <Button
            variant="contained"
            color="warning"
            disabled={!hasSignature}
            onClick={() => stampSignature(true)}
            sx={{ mt: 2, float: "left" }}
          >
            Remove
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setShowSigns(false)}
            sx={{ mt: 2, float: "right" }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      <Container fluid>
        <Title title={"Add eSignature"} viewActive={false} />
      </Container>
      <WrapperLabeling>
        <ToastContainer autoClose={3000} />
        <Wrapper>
          <Nav>
            <Ul>
              <LiBack>
                <Button
                    color="primary"
                    size="large"
                    onClick={() => { navigate('/home') }}
                    startIcon={<ArrowBackIosNewIcon />}
                    sx={{textTransform: 'none'}}
                >
                  Back
                </Button>
              </LiBack>
              <LiDelete>
                {enableTrain === true && (
                    <Button
                        title="Sign document"
                        variant="contained"
                        size="large"
                        onClick={requestEsignature}
                        style={{ marginLeft: "10px", textTransform: 'none', width: '150px' }}
                        color="success"
                        component="span">
                      <FingerPrintIcon />  eSign
                    </Button>
                )}
              </LiDelete>
            </Ul>
          </Nav>

          <Grid container className="toolbarLabeling" >
            <Grid item xs={2} md={2} style={{ paddingRight: '20px' }}>

            </Grid>
            <Grid item xs={8} md={8} sx={{ textAlign: 'left' }}>
            </Grid>
            <Grid item xs={2} md={2} style={{ paddingRight: '20px' }}>

            </Grid>
          </Grid>
        </Wrapper>
        <Grid container >
          <Grid className="wrapperAside" item xs={2} md={2}>
            <DocumentListDocPlace
              documents={documents}
              deleteOCR={deleteOCR}
              getDocument={getDocument}
            />
          </Grid>
          <Grid className="wrapperAside" item xs={10} md={10}>
            <div className="documentShowcase">
              <ReactRegion
                maxRegions={1000}
                regions={regions}
                onChange={onChange}
                regionStyle={regionStyle}
                regionRenderer={regionRenderer}
                constraint
                onClick={selectRegion}
              >
                <img
                  style={{ width: "100%", pointerEvents: "none" }}
                  src={currentDocument}
                  alt="Current document"
                />
              </ReactRegion>
            </div>
          </Grid>

        </Grid>
      </WrapperLabeling>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingTree: state.loadingFolders,
    tree: state.folders,
  };
};

export default connect(mapStateToProps, {})(StampSignature);
