import { all, fork } from "redux-saga/effects";
import LoginSaga from "./Auth/sagas";
import DocumentSaga from "./Document/saga";
import UserValidateSaga from "./ESignature/saga";
import DocViewerSaga from "./DocViewer/saga";
import AllFormsRequestedSaga from "./Forms/saga";

export default function* rootSaga() {
  yield all([
    fork(LoginSaga),
    fork(DocumentSaga),
    fork(UserValidateSaga),
    fork(DocViewerSaga),
    fork(AllFormsRequestedSaga),
  ]);
}
