import {call, put, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";

import {apiErrorDeleteDoc, apiErrorDocDownload, postDeleteDocSuccess, postDocDownloadSuccess} from "./actions";
import {postDeleteDocAPI, postDownloadDocAPI} from "../../Helpers/call_apis";
import {DOC_DELETE_POST, DOC_DOWNLOAD_POST} from "./actionTypes";

function* postDeleteDoc({ payload: { deleteDoc, navigate } }) {
    try {
        const response = yield call(postDeleteDocAPI, deleteDoc);
        yield put(postDeleteDocSuccess(response));
        toast.success("Item was deleted");
    } catch (error) {
        if (error.response.status) {
            if (error.response.status === 401) {
                navigate("/login");
            }
        }
        toast.error(error?.response?.data?.error?.messages[0].message);
        yield put(apiErrorDeleteDoc(error));
    }
}

function* postDocDownload({ payload: { download, navigate } }) {
    try {
        const response = yield call(
            postDownloadDocAPI,
            download.archiveId,
            download.fileName,
        );
        toast.success("Document downloaded");
        yield put(postDocDownloadSuccess(response));
    } catch (error) {
        if (error.response.status) {
            if (error.response.status === 401) {
                navigate("/login");
            }
        }
        toast.error(error?.response?.data?.error?.messages[0].message);
        yield put(apiErrorDocDownload(error));
    }
}

function* authSaga() {
    yield takeEvery(DOC_DELETE_POST, postDeleteDoc);
    yield takeEvery(DOC_DOWNLOAD_POST, postDocDownload);
}

export default authSaga;