import styled from "styled-components";
import { colors } from "../../../Components/theme";

export const WrapperForm = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  background-color: ${colors.ground};
  margin-top: 20px;
  overflow: hidden;
`;

export const DivNav = styled.div`
    width: 100%;
    height: 50px;
    background-color: #4546460a;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;,
`;

export const LiDownload = styled.li`
  display: inline !important;
  margin-right: 35px !important;
  float: right !important;
  margin-top: 6px !important;
`;
export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 4px;

  margin-top: 0px;
  margin-bottom: 10px;
`;
