import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Step1 from "./Components/step1";
import Step3 from "./Components/step3";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { login, register } from "../../Store/Auth/actions";
import Loading from "../../Components/Resources/loading";
import moment from "moment/moment";
import { uploadSignature } from "../../Services/uploadService";

const steps = ["", "", "All set"]; //

const RegisterSteps = (props) => {
  /**BEGIN constant and variables */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const { fullName, email } = useParams(); //get information from url, //token
  const [loadingUpload, setLoadingUpload] = useState(false);
  const isFromDocPlace = email ? true : false;

  const [dataStep1, setStep1] = useState({
    /**General information step */ fullName: fullName ? fullName : "",
    nationality: "",
    dateBirth: "",
    email: email ? email : "",
    confirmEmail: email ? email : "",
    password: "",
    confirmPassword: "",
  });
  const [dataStep2] = useState({
    /**validation ID step */ documentType: "",
    idNumber: "",
    iDate: "",
    eDate: "",
    skipped: true, //true to skip the STEP 2, //change to false, when add the validation ID again
  });
  const [dataStep3, setStep3] = useState({
    /**Signature step */ tycAccepted: false,
    sign: "",
    signText: "",
    rawSign: "",
  });

  /**Registe  user */
  const { errorRegister, responseRegister, loadingRegister } = useSelector(
    (state) => ({
      errorRegister: state.Auth.errorRegister,
      responseRegister: state.Auth.responseRegister,
      loadingRegister: state.Auth.loadingRegister,
    })
  );

  /**Login data*/
  const { errorLogin, responseLogin, loadingLogin } = useSelector((state) => ({
    errorLogin: state.Auth.errorLogin,
    responseLogin: state.Auth.responseLogin,
    loadingLogin: state.Auth.loadingLogin,
  }));

  /**change the title of the page */
  useEffect(() => {
    //executer just the first time
    document.title = "Register";
  }, []);

  /**END constant and variables */

  /**BEGIN Navigation methods */
  /**go back of the previous step:  It should be removed */
  const back = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  /**go to the next step */
  const next = () => {
    if (activeStep < steps.length) setActiveStep(activeStep + 1);
  };
  /**Navigate to home */
  const navigateHome = () => {
    navigate("/home");
  };
  /**END navigation methods */

  /**BEGIN creation of esignature user */
  /**Start by creating the register, then make login, and then upload the image, this is the first step */
  const createUser = () => {
    //we make the validations before this point, on step1 and step 3. Therefore, all the information is validated previously
    let inputDate = dataStep1.dateBirth;
    let dateObj = moment(inputDate, "MM/DD/YYYY");
    let formattedDate = dateObj.format("YYYY-MM-DD");
    let fname = dataStep1.fullName.trim().split(" ")[0];
    let lname =
      dataStep1.fullName.trim().split(" ").length > 1
        ? dataStep1.fullName.substring(fname.length)
        : "";
    let user = {
      birthDate: formattedDate,
      nationality: dataStep1.nationality,
      typeOfId: "No provided",
      idExpiration: "2023-05-23T04:24:35.998Z",
      issueDate: "2023-05-23T04:24:35.998Z",
      firstName: fname,
      lastName: lname,
      email: dataStep1.email,
      displayName: dataStep1.fullName,
      password: dataStep1.password,
      acceptTermsAndConditions: true, //they can't reach to here without accepting terms and conditions
      type: "EXTERNAL",
    };
    dispatch(register(user, navigate));
  };

  /**Here we will chain the login and the call to the setup of the signature */
  useEffect(() => {
    //this is the response of the call on createUser method
    if (responseRegister) {
      //the creation of the esignature external user was successfully
      console.log(responseRegister);
      executeLogin(dataStep1.email, dataStep1.password);
    }
  }, [responseRegister]);

  /**Error on creating the esignature user, we finish the process */
  useEffect(() => {
    if (errorRegister) {
      toast.error(
        "System responded with: " +
          errorRegister?.response?.data?.error?.messages[0]?.message
      );
    }
  }, [errorRegister]);

  /**This method, make a login process, it is called on the correct response of createUser method*/
  const executeLogin = (username, password) => {
    let obj = {
      email: username,
      password: password,
    };
    dispatch(login(obj, navigate));
  };

  /**Responses from login */
  //The login was success
  //we make a login to can use the upload of the signature without splitting the process. but it will need 3 calls. this is the second response
  useEffect(() => {
    if (responseLogin) {
      responseLogin.customerId = ""; //for esignature we don't have a customer id
      setLoadingUpload(true);
      localStorage.setItem("esignature", JSON.stringify(responseLogin)); //we set the user information to the localstore
      uploadSignature(
        convertToImg(dataStep3.rawSign),
        dataStep1.fullName,
        dataStep1.email,
        (event) => {}
      )
        .then((response) => {
          //Everything as expected, we created and uploaded the files correctly
          toast.success("Esignature registered successfully!");
          setLoadingUpload(false);
          next();
        })
        .catch((error) => {
          //there was an issue in the last step.
          setLoadingUpload(false);
          toast.error(
            "Esignature info success, but signature upload failed! Error: " +
              error?.response?.data?.error?.messages[0]?.message
          );
        });
    }
  }, [responseLogin]);

  //The login failed
  useEffect(() => {
    if (errorLogin) {
      toast.error(
        "Error creating user, we were not able to login, Error: " +
          errorLogin?.response?.data?.error?.messages[0]?.message,
        "error"
      );
    }
  }, [errorLogin]);

  /**Convert the image base 64 to a png image file
   * We use this, to upload as a form file parameter to the server
   */
  const convertToImg = (base64Image) => {
    const byteCharacters = atob(
      base64Image.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: "image/png" });
  };

  /**END of creation esignature user */

  return (
    <Box sx={{ width: "100%", marginTop: "30px" }}>
      <ToastContainer />
      <Loading
        show={loadingRegister || loadingLogin || loadingUpload}
        text="Creating Esignature user"
      />
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <Box sx={{ marginTop: "30px" }}>
          {activeStep === 0 && (
            <Step1
              back={back}
              next={next}
              data={dataStep1}
              isFromDocPlace={isFromDocPlace}
              setData={setStep1}
            />
          )}
          {activeStep === 1 && (
            <Step3
              back={back}
              next={next}
              data={dataStep3}
              setData={setStep3}
              createUser={createUser}
            />
          )}
          {activeStep === 2 && (
            <Box textAlign="center">
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 2, mr: 2 }}
                onClick={navigateHome}
                color="success"
              >
                Go to your esignature
              </Button>
            </Box>
          )}
        </Box>
      </React.Fragment>
    </Box>
  );
};

export default RegisterSteps;
