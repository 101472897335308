import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";
import {
    /**get the list of all documents */
    DOCUMENT_LIST_CLEAR_DATA,
    DOCUMENT_LIST,
    /**Stamp esignature */
    LIST_REQUIRE_SIGNATURE_DOCUMENT,
    LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA,
    DOCUMENT_PAGES_LIST,
} from "./actionType"
import {
    /**get the list of all documents */
    documentListSuccess,
    documentListClearData,
    documentListError,

    /**stamp esignature */
    apiErrorDocumentPage,
    documentPageSuccess,

    getListRequireSignatureDocumentError,
    getListRequireSignatureDocumentSuccess,
} from "./actions"
import { getDocumentList,  postDocumentPages, getListRequireSignatureDocumentAPI } from "../../Helpers/call_apis"

/**Get document List */
function* documentClearData() {
    try {
        yield put(documentListClearData())
    } catch (error) {
        yield put(documentListError(error))
    }
}

function* documentGetList({ payload: { navigate } }) {
    try {
        const response = yield call(getDocumentList)
        yield put(documentListSuccess(response));
    } catch (error) {
        console.log(error);
        yield put(documentListError(error));
    }
}

/**STAMP esignature */
function* getDocumentPageList({
    payload: { documentPages, page, size, navigate },
}) {
    try {
        const response = yield call(
            postDocumentPages,
            documentPages.id,
            page,
            size
        );
        yield put(documentPageSuccess(response));
    } catch (error) {
        if (error.response.status) {
            if (error.response.status === 401) {
                navigate("/");
            }
        }
        yield put(apiErrorDocumentPage(error));
        if (
            error.response?.data?.error?.messages[0].message
                .toLowerCase()
                .includes("document doesn't exists")
        ) {
            toast.error("Document cannot be opened, reason: Document is empty.", {
                toastId: "document-empty",
            });
        } else if (
            error?.response?.data?.error?.messages[0].message
                .toLowerCase()
                .includes("extension not supported")
        ) {
            toast.error(
                "Document cannot be opened, reason: Extension is not supported.",
                { toastId: "extension-notsupported" }
            );
        }
    }
}
// List require signature for document
function* getListRequireSignatureDocument({ payload: { id } }) {
    try {
        const response = yield call(getListRequireSignatureDocumentAPI, `?DocumentId=${id}`);
        yield put(getListRequireSignatureDocumentSuccess(response));
    } catch (error) {
        yield put(getListRequireSignatureDocumentError(error));
    }
}

function* listRequireSignatureDocumentClearData() {
    try {
        yield put(listRequireSignatureDocumentClearData(true))
    } catch (error) {
        yield put(getListRequireSignatureDocumentError(error))
    }
}
// END


function* authSaga() {
    /**Document list clear data
     * 
     */
    yield takeEvery(DOCUMENT_LIST_CLEAR_DATA, documentClearData);
    yield takeEvery(DOCUMENT_LIST, documentGetList);
    /**Stamp esignature calls */
    yield takeEvery(DOCUMENT_PAGES_LIST, getDocumentPageList);
    yield takeEvery(LIST_REQUIRE_SIGNATURE_DOCUMENT, getListRequireSignatureDocument);
    yield takeEvery(LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA, listRequireSignatureDocumentClearData);
}


export default authSaga
