import styled from 'styled-components';
//import { makeStyles } from '@material-ui/core/styles';
import {colors} from '../../Components/Resources/index';

export const WrapperLabeling = styled.div`
    width: 100%;
    height: calc(100vh - 50px);
    background-color: ${colors.ground};
    margin-top: 20px;
    overflow: hidden;
`;
export const Wrapper = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 4px;

    margin-top: 0px;
    margin-bottom: 10px;
`;
export const TemplateLabeling = styled.article`
    width: 200px;
    height: 100%;
    background-color: red;
    float: left;
`;

export const AttributesLabeling = styled.article`
    width: 200px;
    height: 100%;
    background-color: blue;
    float: right;
`;

export const Labeling = styled.article`
    min-width: 1000px;  
    width: auto;
    height: 100%;
    background-color: #000;
    float: left;
`;

export const TitleTemplate = styled.h4`
      color: #006ac6;
      margin: 10px auto;
      text-align: center;
      font-weight: 900;
`;

export const WrapperDocumentTemplate = styled.section`
      width: 100%;
      padding: 10px 0px 10px 0px;
      cursor: pointer;
      :hover{
        background-color: #e8e6e6;
      }
`;

export const DocumentTemplate = styled.section`
      margin: 10px auto;
      text-align: center;
      width: 120px;
      height: 120px;
      background-color: #9e9e9e;
`;

export const DocumentNameTemplate = styled.div`
      color: #908888;
      text-align: center;
      font-weight: 100;
      margin: -7px 0px 10px 0;
      word-break: break-word;
      line-height: 1;
`;

export const WrapperDocumentAttribute = styled.section`
      width: 100%;
      padding: 10px 0px 10px 0px;
      cursor: pointer;
`;

export const DocumentAttributeItem = styled.div`
      width: 60%;
      margin: 0px auto;
      cursor: pointer;
      background-color: #607d8b;
      height: 25px;
      text-align: center;
      position: relative;
      padding-top: 2px;
      border-radius: 5px;
      color: #fff;
      font-weight: 100;
      :hover{
        font-weight: bold;
      }
`;

export const DocumentAttributeType = styled.section`
      width: 100%;
      padding: 10px 0px 10px 0px;
      cursor: pointer;
`;

export const DocumentAttributeLine = styled.div`
        width: 2px;
        height: 20px;
        background-color: #000;
        margin: -7px auto;
`;

export const DocumentAttributeText = styled.div`
        width: 100%;
        height: 20px;
        text-align: center;
        margin-top: 5px;
`;

export const MenuContent = styled.nav`
          width: 99%;
          height: 40px;
          background-color: ${colors.white};
          margin-top: 8px;
          display: inline-block;
          box-shadow: 0px 2px 5px #9e9e9e;
          border-radius: 5px;
`;


export const MenuContentUL = styled.ul`
          margin: 0;
          padding: 0;
          list-style: none;
`;

export const MenuContentLI = styled.li`
          margin: 0;
          padding: 0;
          float: left;
          color: #006ac6;
          margin: 4px 15px 0px 14px;
          cursor: pointer;
          :hover{
            font-weight: 600;
          }
`;

export const TitleMenu = styled.span`
          margin-top: 5px;
          display: inline-block;
          float: right;
`;

export const LabelingContent = styled.article`
          width: 99%;
          height: calc(100vh - 110px);
          background-color: ${colors.white};
          box-shadow: 0px 2px 5px #9e9e9e;
          margin-top: 5px;
          overflow: auto;
`;

export const Nav = styled.nav`
    border-radius: 5px;
    background-color: #4546460a;
    width: 100%;
`;

export const Ul = styled.ul`
  position: relative;
  list-style-type: none;
  display: inline-block;
  width: 100%;
  height: 20px;
`;

export const LiBack = styled.li`
  display: inline-block;
  float: left;
  margin: -8px 0 0 -20px;
`;

export const LiDelete = styled.li`
  display: inline;
  margin-right: 25px;
  float: right;
  margin-top: -7px ;
  color: red;
`;