import React from "react";
import { MenuItem, TextField } from "@mui/material";

const SelectDropdownDocPlace = ({
  field,
  setField,
  label,
  helperText,
  id,
  required,
  defaultList,
  arrayList,
  isMulti,
}) => {
  return (
    <TextField
      id={id}
      select
      label={label}
      value={field.value}
      onChange={(e) => {
        setField({ ...field, value: e.target.value });
      }}
      helperText={helperText}
      variant="outlined"
      required={required}
      sx={{
        margin: "0px",
        width: "100% !important",
      }}
    >
      {arrayList.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectDropdownDocPlace;
