import {makeStyles} from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    inputForm: {
        margin: '0px 0px 22px 0px !important',
        width: '100%',
        zIndex: '0'
    },
    helperText: {
        fontSize: '12px',
        backgroundColor:'#607d8b3d',
        padding: '5px',
        borderRadius: '3px'
    },
    selectControl: {
        height: '56px !important',
        zIndex: '999'
    }
}));


  



