import { del, get, getDoc, post } from "./apis";
import * as url from "./url";

//create esignature user
const postRegisterUser = (data) => post(url.REGISTER_USER_ESIGNATURE, data);
/**END*/

/* Page login
list API's*/
const postLogin = (data) => post(url.POST_LOGIN, data);
//forget password

/**BEGIN DOCUMENT */
const getDocumentList = () => get(url.GET_DOCUMENT_LIST);
/**END document */

//DocViewerListRequireSignature
const getListRequireSignatureDocumentAPI = (id) =>
  get(url.LIST_REQUIRE_SIGNATURE_DOCUMENT_API + id);
const getValidateUserSignatureAPI = () => get(url.VALIDATE_USER_SIGNATURE);
const getRequiredSignatureAPI = () => get(url.GET_REQUIRED_SIGNATURE_API); //REFACTOR NEEDED, we need to avoid the duplicate
const resendSignatureRequestEmailAPI = (data, documentId) =>
  post(url.RESEND_SIGNATURE_REQUEST_EMAIL, data, documentId);

//document pages
const postDocumentPages = (id, page, size) =>
  post(
    url.GET_DOCUMENT_PAGES + "/" + id + "?page=" + page + "&pageSize=" + size
  );
//END

// DOC VIEWER
const postDeleteDocAPI = (data) => del(`${url.POST_DELETE_DOC}, ${data}`);
const postDownloadDocAPI = (archiveId, fileName, type) =>
  getDoc(`${url.POST_DOWNLOAD_DOC}/${archiveId}/Download`);
// END
// getDoc(url.POST_DOWNLOAD_DOC + "/" + archiveId + "/Download" + "");

//AllFormsRequested
const getAllFormsRequestAPI = (data) =>
  post(url.GET_ALL_FORMS_REQUESTED_API, data);
//END AllFormsRequested

export {
  //create Esignature data
  postRegisterUser,
  //Auth login esignature
  postLogin,
  //document list
  getDocumentList,
  /**stamp signature */
  getListRequireSignatureDocumentAPI,
  getValidateUserSignatureAPI,
  resendSignatureRequestEmailAPI,
  getRequiredSignatureAPI,
  postDocumentPages,
  // DOC VIEWER
  postDeleteDocAPI,
  postDownloadDocAPI,
  // GET ALL FORMS REQUEST
  getAllFormsRequestAPI,
};
